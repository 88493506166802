// Computes a top-shadow for a card effect.
// @param {Number} $depth - depth level
// @return {List}

@function top-shadow($depth) {
    $primary-offset: nth($shadowOffsetsTop, $depth) * 1px;
    $blur: nth($shadowBlursTop, $depth) * 4px;
    $color: rgba(black, nth($shadowOpacitiesTop, $depth));

    @return 0 $primary-offset $blur $color;
}

// Computes a bottom-shadow for a card effect.
// @param {Number} $depth - depth level
// @return {List}
@function bottom-shadow($depth) {
    $primary-offset: nth($shadowOffsetsBottom, $depth) * 1px;
    $blur: nth($shadowBlursBottom, $depth) * 5px;
    $color: rgba(black, nth($shadowOpacitiesBottom, $depth));
    @return 0 $primary-offset $blur $color;
}

@mixin clearfix() {
    &::after {
        display: block;
        content: "";
        clear: both;
    }
}


//Responsive Breakpoints
$breakpoints: (
        xxs: 460px,
        xs: 576px,
        sm: 780px,
        md: 992px,
        lg: 1200px,
        xl: 1440px,
        xxl: 1700px
);

@mixin respond-below($breakpoint) {
    // If the breakpoint exists in the map.
    @if map-has-key($breakpoints, $breakpoint) {
        // Get the breakpoint value.
        $breakpoint-value: map-get(
                        $breakpoints,
                        $breakpoint
        ); // Write the media query.
        @media (max-width: ($breakpoint-value - 1)) {
            @content;
        }
        // If the breakpoint doesn't exist in the map.
    } @else {
        // Log a warning.
        @warn "Invalid breakpoint: #{$breakpoint}.";
    }
}

@mixin respond-above($breakpoint) {
    // If the breakpoint exists in the map.
    @if map-has-key($breakpoints, $breakpoint) {
        // Get the breakpoint value.
        $breakpoint-value: map-get(
                        $breakpoints,
                        $breakpoint
        ); // Write the media query.
        @media (min-width: ($breakpoint-value)) {
            @content;
        }
        // If the breakpoint doesn't exist in the map.
    } @else {
        // Log a warning.
        @warn "Invalid breakpoint: #{$breakpoint}.";
    }
}

@mixin respond-landscape-below($breakpoint) {
    // If the breakpoint exists in the map.
    @if map-has-key($breakpoints, $breakpoint) {
        // Get the breakpoint value.
        $breakpoint-value: map-get(
                        $breakpoints,
                        $breakpoint
        ); // Write the media query.
        @media screen and (orientation: landscape) and (max-width: $breakpoint-value) {
            @content;
        }
    }
        // If the breakpoint doesn't exist in the map.
    @else {
        // Log a warning.
        @warn "Invalid breakpoint: #{$breakpoint}.";
    }
}

// * IE10+ specific styles go here */
@mixin target-ie-browser() {
    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        /* IE10+ specific styles go here */
        @content;
    }
}

@mixin keep-ratio($height, $width) {
    &::before {
        content: "";
        width: 1px;
        margin-left: -1px;
        float: left;
        height: 0;
        padding-top: $height / $width * 100%;
    }

    &::after { /* to clear float */
        content: "";
        display: table;
        clear: both;
    }
}

@mixin hide-background($background-color) {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background-image: linear-gradient(
                    $background-color 50px,
                    transparent 50px,
                    transparent 100%
    );

    @include respond-below(xl) {
        background-image: linear-gradient(
                        $background-color 30px,
                        transparent 30px,
                        transparent 100%
        );
    }

    @include respond-below(md) {
        background-image: linear-gradient(
                        $background-color 39px,
                        transparent 39px,
                        transparent 100%
        );
    }

    @include respond-below(sm) {
        background-image: linear-gradient(
                        $background-color 32px,
                        transparent 32px,
                        transparent 100%
        );
    }

    @include respond-below(xs) {
        background-image: linear-gradient(
                        $background-color 25px,
                        transparent 25px,
                        transparent 100%
        );
    }

    @include respond-below(xxs) {
        background-image: linear-gradient(
                        $background-color 20px,
                        transparent 20px,
                        transparent 100%
        );
    }

    @include respond-landscape-below(sm) {
        background-image: linear-gradient(
                        $background-color 25px,
                        transparent 25px,
                        transparent 100%
        );
    }
}

@mixin background-image-cover($height, $width) {
    height: $height;
    width: $width;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

@mixin background-image-contain($height, $width) {
    height: $height;
    width: $width;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

@mixin carousel-arrow($color, $hoverColor) {
    .arrow {
        border: solid $color;
        border-width: 0 1px 1px 0;
        //height: 26px;
        //width: 21px;
        display: inline-block;
        padding: 13px;
        cursor: pointer;
        transition: border-color 200ms;

        @include respond-below(sm) {
            padding: 9px;
        }

        &:hover {
            border-color: $hoverColor;
        }

        &.right {
            transform: rotate(-45deg);
        }

        &.left {
            transform: rotate(135deg);
        }
    }
}

@mixin hover-supported {
    @media not all and (pointer: coarse) {
        &:hover {
            @content;
        }
    }
}

@mixin test-border($color) {
    box-sizing: border-box;
    border: 1px solid $color;

    * {
        box-sizing: border-box;
        border: 1px solid $color;
    }
}

@mixin debug($color: red) {
    box-sizing: border-box;
    border: 1px dashed $color;

    * {
        box-sizing: border-box;
        border: 1px solid $color;
    }
}