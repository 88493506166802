// ========================
//      Table of Contents
//  - Colors
//  - Dimensions
//  - Animations & Transition Constants
//  - Imports
//  - General
//  - Containers & App Components
//    .Top Nav
//    .Menu
//    .Footer
//  - Pages Components
//    .Page
//    .HeadlineWithText
//    .Paragraph
//    .TwoImagesWithLabel
//    .Locations
//    .DetailsHover
//    .WOMs
//    .Subscribe
//    .ImageCarousel
//    .TwoImagesWithLabelCarousel
//    .HotelPublicPlacesCarousel
//    .HotelPublicPlaces
//    .HeaderHome
//    .BackgroundSlider
//    .InstagramPhotoGrid
//    .HeaderTwoParagraphs
//    .HeaderTwoParagraphsTwoImages
//    .CollectionIndex
//    .header-woms
//    .woms-description
//    .SingleWOMCarousel
//    .BackgroundWithButton
//    .ContactHotels
//    .SingleContactHotel
//    .HeaderOneImage
//    .ContactUsModal
//    .HeaderHotel
//    .TwoImagesHorizontal
//    .PageNavigationPanel
//    .GalleryPageNavigationPanel
//    .CollectionItemHeader
//    .CollectionDetails
//    .SingleImageCenter
//    .SingleImageWide
//    .HeadlineBig
//    .HeadlineSmall
//    .ArticleImage
//    .ArticleParagraph
//    .FAQ
//    .SimplexContainer
//    .BookAWom
//    .ParticipateForm

//    .TheWomLoader
//    .TheWomScrollbar

//  - Colors
//  - Headlines & Text

// ========================


// ===================
//      Colors
// ===================

$theme-color-1: #7ac4c9;
$theme-color-2: #f8c0b7;
$theme-color-3: #f8e05d;
$theme-color-4: #e6e8ea;
$theme-color-5: #ffffff;
$theme-color-6: #202020;

$menu-background: $theme-color-3;

$separator-color-light: #313131;
$separator-color: #424242;
$background-color: $theme-color-5;
$foreground-color: #021929;
$input-background: #021929;

$simplex-separator: #999ea1;
$simplex-input-border-color: #a1a1a1;
$simplex-input-mobile-text-color: #a3a7aa;

$participate-form-input-height: 66px;
$participate-form-input-height-sm: 35px;
$participate-form-input-color: #a3a7aa;

$dark-btn-hover-background: #555454;
$button-text-color: $theme-color-5;
$dark-btn-background: $theme-color-6;
$carousel-arrow-color: $theme-color-1;
$carousel-arrow-color-light: $theme-color-5;

$text-light: $theme-color-5;
$text-dark: $theme-color-6;

$primary-color: #E9E9E9;
$secondary-color: #7B7B94;
$muted-color: $theme-color-4;
$dark-color: $theme-color-6;
$light-color: $theme-color-5;
//$info-color: #3195a5;
$success-color: #3e884f;
//$warning-color: #b69329;
$error-color: #c43d4b;

//$gradient-color-1: #523b2a;
//$gradient-color-2: #493627;
//$gradient-color-3: #754a29;


// ===================
//      Dimensions
// ===================

$main-max-width: 1400px;
$main-min-width: 168px;
$screen-max-width: 1920px;

$navbar-height: 60px;
$navbar-height-sm: 60px;
$navbar-height-on-scroll: 60px;
$navbar-height-on-scroll-sm: 40px;
$navbar-padding: 0 0;
$navbar-padding-xl: 0 1%;
$navbar-padding-sm: 0 1.5%;
$navbar-padding-xs: 0 2%;

$menu-header-padding-sm: 0 1.5rem;

$footer-height: 200px;
$footer-height-sm: 180px;
$footer-height-xs: 180px;
$footer-height-sm-landscape: 65vh;

$section-margin: 0 0 90px 0;
$section-margin-xl: 0 0 85px 0;
$section-margin-lg: 0 0 75px 0;
$section-margin-md: 0 0 70px 0;
$section-margin-sm: 0 0 60px 0;
$section-margin-xs: 0 0 30px 0;

$header-home-margin-bottom: 90px;
$header-home-margin-bottom-xl: 90px;
$header-home-margin-bottom-lg: 80px;
$header-home-margin-bottom-md: 70px;
$header-home-margin-bottom-sm: 60px;
$header-home-margin-bottom-xs: 30px;

$simplex-header-height: 66px;
$simplex-header-input-height: 50px;
$simplex-header-input-height-xs: 35px;
$simplex-input-padding-xs: 0 0 0 10px;
$simplex-input-margin-xs: 0 0 30px;

$page-navigation-panel-margin: -90px 0 90px 0;
$page-navigation-panel-margin-xl: -90px 0 90px 0;
$page-navigation-panel-margin-lg: -80px 0 80px 0;
$page-navigation-panel-margin-md: -70px 0 70px 0;
$page-navigation-panel-margin-sm: -60px 0 60px 0;
$page-navigation-panel-margin-xs: -30px 0 30px 0;

$section-center-max-width: 70%;
$section-center-max-width-xl: 70%;
$section-center-max-width-sm: 89.3%;
$section-center-narrow-max-width: 53%;
$section-center-narrow-max-width-lg: 63%;
$section-center-narrow-max-width-md: 73%;
$section-center-narrow-max-width-sm: 89.3%;
$section-wide-max-width: 100vw;

$header-wide-max-width-xl: 70%;
$header-wide-max-width-sm: 89.3%;

$multi-slide-carousel-height: 65vh !important;
$multi-slide-carousel-height-mobile: 25vh !important;

$single-slide-carousel-height: 80vh;
$single-slide-carousel-height-mobile: 70vh;

$single-slide-carousel-container-height: 80vh;
//$single-slide-carousel-container-max-height:
$single-slide-carousel-container-height-mobile: 70vh;

$hotel-public-places-carousel-container-height: 80vh;
$hotel-public-places-carousel-container-height-mobile: 70vh;

$collection-item-content-padding: 0 6.2%;

$shadowOffsetsTop: 1 3 10 14 19;
$shadowBlursTop: 2 6 10 14 19;
$shadowOpacityTop: 0.1 0.3 0.6 0.7 0.8;

//$shadowOffsetsBottom: 1 3 6 10 15;
//$shadowBlursBottom: 3 6 6 5 6;
//$shadowOpacityBottom: 0.1 0.3 0.6 0.7 0.8;
//
//$logoPath: "/assets/img/logo/thewom_logo.svg";
//$logoPathMobile: "/assets/img/logo/thewom_logo.svg";

// =====================================
//    Animations & Transition Constants
// =====================================

$opacity-transition-speed: 250ms;
$nav-link-transition-speed: 130ms;
$zoom-transition-speed: 250ms;
$top-nav-logo-transition-speed: 600ms;

$image-zoom-in-scale: scale(1.10, 1.10);

$label-opacity-hover: 0.7;
$image-opacity-hover: 0.8;


//* {
//  border: 1px dashed red;
//  box-sizing: border-box;
//}

// ===================
//      Imports
// ===================

@import "../_mixins.scss";
@import "../_main.scss";

// Headlines Font
@font-face {
    font-family: 'Headlines-Font';
    src: url('../../../fonts/CrimsonText-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Headlines-Font';
    src: url('../../../fonts/Heebo-Regular.ttf') format('truetype'),
    url('../../../fonts/Heebo-Medium.ttf') format('truetype'),
    url('../../../fonts/Heebo-Bold.ttf') format('truetype'),
    url('../../../fonts/Heebo-ExtraBold.ttf') format('truetype');
    unicode-range: U+0590-05FF; // Hebrew glyphs
}

// Text Font
@font-face {
    font-family: 'Text-Font';
    src: url('../../../fonts/GT-Haptik-Regular.ttf') format('truetype'),
    url('../../../fonts/GT-Haptik-Regular.eot') format('embedded-opentype'),
    url('../../../fonts/GT-Haptik-Regular.woff2') format('woff2'),
    url('../../../fonts/GT-Haptik-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Text-Font';
    src: url('../../../fonts/Heebo-Regular.ttf') format('truetype'),
    url('../../../fonts/Heebo-Medium.ttf') format('truetype'),
    url('../../../fonts/Heebo-Bold.ttf') format('truetype'),
    url('../../../fonts/Heebo-ExtraBold.ttf') format('truetype');
    unicode-range: U+0590-05FF; // Hebrew glyphs
}


// ===================
//      General
// ===================

// Disable Select or Drag Text
//* {
//    user-select: none;
//    -webkit-user-drag: none;
//}

// Text Selection - Entire App
::-moz-selection {
    background: $theme-color-2;
}

::selection {
    background: $theme-color-2;
}

html, body {
    font-size: 18px;
    font-family: 'Text-Font', sans-serif;
    background-color: $background-color;
    overflow-x: hidden;
    overflow-y: auto;

    @include respond-below(xl) {
        font-size: 18px;
    }

    @include respond-below(lg) {
        font-size: 17px;
    }

    @include respond-below(md) {
        font-size: 15px;
    }

    @include respond-below(sm) {
        font-size: 14px;
    }

    @include respond-below(xs) {
        font-size: 13px;
    }

    @include respond-below(xxs) {
        font-size: 12px;
    }
}

// ====================
//   Headlines & Text
// ====================

h1, h2, h3, h4, h5, h6 {
    font-family: 'Headlines-Font', sans-serif;
}

.section-title {
    font-size: 4rem;
    font-family: 'Headlines-Font', sans-serif;
    margin: 0 0 39.5px 0;
    line-height: 1.14;
    //text-shadow: 0 8.5px 13.5px rgba(0, 0, 0, 0.3);

    @include respond-below(xl) {
        font-size: 2.8rem;
    }

    @include respond-below(sm) {
        font-size: 3.5rem;
        margin: 0 0 15px 0;
    }

    @include respond-below(xs) {
        font-size: 3rem;
    }

    @include respond-below(xxs) {
        font-size: 2.5rem;
    }
}

.section-subtitle {
    font-size: 2rem;
    font-family: 'Headlines-Font', sans-serif;
    line-height: 1.33;
    margin: 0;
}

.label-title {
    font-size: 2rem;
    font-family: 'Headlines-Font', sans-serif;
    margin: 0;
    //text-shadow: 0 1px 1px rgba(0, 0, 0, 0.49);

    @include respond-below(sm) {
        font-size: 2.5rem;
    }

    @include respond-below(xs) {
        font-size: 2rem;
    }

    @include respond-below(xxs) {
        font-size: 1.75rem;
    }
}

.paragraph {
    font-size: 1rem;
    margin: 0;
}

a {
    text-decoration: none !important;
    color: $theme-color-1;
    transition: color $nav-link-transition-speed;
    user-select: none;
    -webkit-user-drag: none;

    &:hover {
        color: $dark-color;

    }
}

button {
    width: 208px !important;
    height: 65px !important;
    cursor: pointer !important;
    font-size: 1.2rem;
    letter-spacing: 0.1rem;

    @include respond-below(xxs) {
        font-size: 1.4rem;
    }
}

header {
    margin-bottom: 2.5rem;
}

// !! Use in all sections
// className={'d-flex flex-column justify-content-start align-items-center'}
section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin: $section-margin;
    width: 100%;

    &:last-of-type {
        margin-bottom: 5rem;
    }

    &.header {
        margin-top: 60px;
        max-width: $main-max-width;
    }

    &.header-wide {
        width: 100%;

        @include respond-below(xl) {
            width: $header-wide-max-width-xl;
        }

        @include respond-below(sm) {
            width: $header-wide-max-width-sm;
        }
    }

    &.wide {
        width: $section-wide-max-width;
        max-width: $screen-max-width;
    }

    &.center {
        width: $section-center-max-width;
    }

    &.center-narrow {
        width: $section-center-narrow-max-width;
    }

    &.collection-item-center {
        width: 82.81%;

        @include respond-below(xl) {
            width: $section-center-max-width
        }

        @include respond-below(sm) {
            width: $section-center-max-width-sm;
        }
    }

    &.image-center {
        width: 100%;

        @include respond-below(xl) {
            max-width: 82.81%;
        }

        @include respond-below(sm) {
            max-width: $header-wide-max-width-sm;
        }
    }

    .section-title-overlap {
        text-align: center;
        z-index: 1;
        transform: translate(0, 55%);
    }

    @include respond-below(xl) {
        margin: $section-margin-xl;

        &.center {
            width: $section-center-max-width-xl;
        }
    }

    @include respond-below(lg) {
        margin: $section-margin-lg;

        &.center-narrow {
            width: $section-center-narrow-max-width-lg;
        }
    }

    @include respond-below(md) {
        margin: $section-margin-md;

        &.center-narrow {
            width: $section-center-narrow-max-width-md;
        }
    }

    @include respond-below(sm) {
        margin: $section-margin-sm;

        &.center {
            width: $section-center-max-width-sm;
        }

        &.center-narrow {
            width: $section-center-narrow-max-width-sm;
        }

        &.header {
            width: 100% !important;
        }
    }

    @include respond-below(xs) {
        margin: $section-margin-xs;
    }
}

h1 {
    font-size: 4rem;
}

h2 { //H1 in Design UI Kit. Page Headlines
    font-size: 4rem;
}

h3 { //H1 in Design UI Kit. Sections Headlines
    font-size: 4rem;
}

h4 { //H2 in Design UI Kit
    font-size: 2rem;
}

h5 { //H3 in Design UI Kit
    font-size: 1.333rem;
}

p {
    font-size: 1rem;
}

.page-headline {
    margin-bottom: 2rem;
}

.hidden {
    position: absolute;
    top: -9999px;
    left: -9999px;
}

.the-wom-btn {
    min-width: 207px !important;
    height: 66px !important;
    width: 207px !important;
    cursor: pointer !important;
    font-size: 18px;
    line-height: 3;
    display: flex;
    justify-content: center;
    align-items: center;

    @include respond-below(sm) {
        //font-size: 1.28rem;
    }
}

.dark-button {
    background-color: $dark-btn-background;
    color: $text-light !important;
    border: none;
    transition: background-color 200ms;

    &:hover {
        //opacity: 0.7;
        background-color: $dark-btn-hover-background;
        color: $text-light;
    }
}

.success-button {
    background: $theme-color-1;
    color: $theme-color-5;
    border: none;
}

.failure-button {
    background: crimson;
    color: $theme-color-5;
    border: none;
}

.load-button {
    background: $theme-color-6;
    color: $theme-color-5;
    border: none;
    width: 208px !important;
    height: 65px !important;
    cursor: pointer !important;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

// ===================
//        Main
// ===================

main {
    max-width: $main-max-width;
    min-width: $main-min-width;
    // min-height: calc(100vh - 405px - 60px);
    min-height: calc(100vh - 60px);
    margin: $navbar-height auto $footer-height;
    display: flex;
    align-items: center;
    flex-direction: column;
    // width: 85%;
    width: 100%;

    @include respond-below(md) {
        min-height: calc(100vh - 60px);
        margin: $navbar-height auto $footer-height-sm;
        width: 100%;
    }

    @include respond-below(xs) {
        min-height: calc(100vh - 60px);
        margin: $navbar-height auto $footer-height-xs;
    }
}

#app-container {
    overflow: hidden;
    display: block;
    position: relative;
}

// ===================
//   Navigation Bars
// ===================
// Top Bar:
#thewom-top-nav {
    position: fixed;
    height: $navbar-height;
    width: 100%;
    z-index: 1000;

    @include respond-below(sm) {
        height: $navbar-height-sm;
    }

    .thewom-language-icon-container {
        //@include debug();
        height: 100%;
        padding: 9px 0;
        justify-content: flex-start;
        align-items: center;
        margin-left: 30px;

        img {
            width: 25px;
            border-radius: 3px;
        }
    }

    .nav-main {
        width: 100%;
        max-width: $main-max-width;
        min-width: $main-min-width;
        height: 100%;
        margin: 0 auto;
        padding: $navbar-padding;
        -webkit-transform: translate3d(0, 0, 0);

        @include respond-below(xl) {
            max-width: $section-center-max-width-xl;
        }

        @include respond-below(sm) {
            max-width: $section-center-max-width-sm;
        }

        .nav-section {
            width: 40%;

            &.left {
                .hamburger-icon {
                    //padding: 3px 0;
                    cursor: pointer;
                    width: 30px;
                    height: 30px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    filter: invert(100%);

                    &:hover {
                        opacity: 0.7;
                    }

                    .hamburger-row {
                        width: 100%;
                        height: 2px;
                        background-color: $background-color;
                    }
                }
            }

            &.right {
                .btn-link {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    width: 110px;
                    height: 110px;
                    margin-top: 20px;
                    border-radius: 50%;
                    background-color: $theme-color-3;
                    border: none;
                    padding: 0;
                    text-align: center;
                    color: $dark-color !important;
                    cursor: pointer !important;
                    font-size: 1.2rem;
                    font-weight: 700;
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                    text-decoration: none;
                    transition: $nav-link-transition-speed;

                    @include respond-below(md) {
                        width: 100px;
                        height: 100px;
                        font-size: 1.3rem;
                    }

                    @include respond-below(sm) {
                        margin-top: 10px;
                        width: 80px;
                        height: 80px;
                        font-size: 1.2rem;
                    }

                    @include respond-below(xxs) {
                        margin-top: 10px;
                        width: 70px;
                        height: 70px;
                        font-size: 1.2rem;
                    }
                }
            }
        }

        .nav-logo {
            transition: opacity 200ms;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            margin: 10px 0 0;
            padding: 0;
            width: 152px;
            min-width: 152px;
            height: 43px;
            min-height: 43px;
            cursor: pointer;
            opacity: 0;

            @include respond-below(xxs) {
                height: 33px;
                min-height: 33px;
                min-width: 80px;
            }
        }
    }

    &.regular-nav {
        background: $background-color;

        .nav-main {
            .nav-section {
                &.left {
                    .hamburger-icon {
                        filter: invert(0%);

                        &:hover {
                            opacity: 0.7;
                        }

                        .hamburger-row {
                            background-color: $theme-color-6 !important;
                        }
                    }
                }

                &.right {
                    .btn-link {
                        color: $theme-color-6 !important;
                    }
                }
            }

            .nav-logo {
                opacity: 1;
            }
        }
    }
}

// ============
//     Menu
// ============
.menu-container {
    flex-flow: row wrap;
    height: 100vh;
    width: 100vw;
    top: 0;
    position: fixed;
    z-index: 1005;

    * {
        font-family: Headlines-Font, sans-serif;
    }

    @include respond-below(sm) {
        background: $menu-background;
        width: 90vw;
    }

    .menu-background {
        position: absolute;
        width: 50%;
        height: 100%;

        @include respond-below(sm) {
            &.image {
                display: none;
            }

            &.color {
                width: 100%;
            }
        }

        &.color {
            background-color: $menu-background;
            top: 0;
            left: 0;
        }

        &.image {
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            top: 0;
            right: 0;
        }
    }

    .menu-logo {
        position: absolute;
        //@include background-image-contain(154px, 764px);
        @include background-image-contain(43px, 152px);
        top: 20.5px;

        @include respond-below(lg) {
            //@include background-image-contain(125px, 578px);
        }

        @include respond-below(md) {
            height: 100px;
            //width: 457.1px;
        }

        @include respond-below(sm) {
            display: none;
        }
    }

    .menu-content-container {
        position: relative;
        height: 100%;
        width: 100%;
        max-width: $main-max-width;


        .menu-header {
            max-width: $main-max-width;
            //height: 23%;
            height: 85px;
            z-index: 10;

            @include respond-below(lg) {
                //height: 20%;
                //height: 150px;
                width: 100%;
                z-index: 6;
            }

            @include respond-below(md) {
                //height: 17%;
                //height: 130px;
            }

            @include respond-below(sm) {
                z-index: 7;
                height: 0;
            }

            .menu-navbar {
                position: fixed;
                height: $navbar-height;
                padding: $navbar-padding;
                max-width: $main-max-width;

                @include respond-below(xl) {
                    margin: $navbar-padding-xl;
                }

                @include respond-below(sm) {
                    margin: $navbar-padding-sm;
                }

                @include respond-below(xs) {
                    margin: $navbar-padding-xs;
                }


                .close-btn {
                    cursor: pointer;
                    font-size: 23px;
                    user-select: none;
                    font-family: Text-Font, sans-serif;
                    transition: font-weight $nav-link-transition-speed;

                    &:hover {
                        font-weight: bold;
                    }
                }
            }
        }

        .menu-content {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-start;
            flex-basis: 100%;
            max-width: $main-max-width;
            height: 100%;
            z-index: 6;

            @include respond-below(sm) {
                height: 100%;
                padding-top: 5px;
            }

            .menu-links {
                flex-basis: 50%;
                height: calc(100vh - 35%);
                align-items: center;
                line-height: 1.35;
                overflow-y: auto;

                @include respond-below(sm) {
                    //margin-top: calc(#{$navbar-height} - 8px);
                    //height: calc(100vh - #{$navbar-height});
                    //height: calc(100vh - 100px);
                    height: calc(100vh - 20%);
                    flex-basis: 100%;
                    line-height: 1.5;
                }

                @include respond-below(xs) {
                    //margin-top: calc(#{$navbar-height} - 8px);
                    height: calc(100vh - 17%);
                }

                .the-wom-scrollbar {
                    &::-webkit-scrollbar {
                        width: 10px;
                    }

                    &::-webkit-scrollbar-track {
                        background-color: rgba(0, 0, 0, 0);
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: $theme-color-1;
                        border-radius: 10px;
                    }
                }


                .route-link {
                    font-size: 2rem;
                    text-decoration: none;
                    color: $dark-color;
                    transition: font-weight $nav-link-transition-speed;

                    &:hover {
                        font-weight: bold;
                    }

                    @include respond-below(sm) {
                        font-size: 3rem;
                    }
                }

                .social-links {
                    flex-wrap: nowrap;
                    margin-top: 20px;

                    @include respond-below(sm) {
                        margin-top: 15px;
                    }

                    .social-icon {
                        height: 26px;
                        width: 26px;
                        background-position: center;
                        background-size: contain;
                        background-repeat: no-repeat;
                        margin: 0 4px;
                        transition: filter $nav-link-transition-speed;

                        &:hover {
                            filter: drop-shadow(0.5px 0.5px 0.5px rgba(0, 0, 0, 1));
                        }
                    }
                }
            }

            .locations {
                flex-basis: 50%;
                height: 100%;
                text-align: center;
                padding-top: 24px;
                overflow-y: auto;

                @include respond-below(sm) {
                    display: none !important;
                }

                .locations-title {
                    color: $light-color;
                    font-size: 1rem;
                    letter-spacing: 0.8px;
                    line-height: 1.67;
                    text-align: center;
                    font-weight: normal;
                }

                .location-link {
                    text-decoration: none;
                    transition: $nav-link-transition-speed;
                    color: $theme-color-3;
                    font-size: 2rem;
                    line-height: 3.9rem;
                    letter-spacing: -0.5px;

                    &:hover {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

// Footer
.footer {
    position: absolute;
    max-height: $footer-height;
    height: $footer-height;
    bottom: 0;
    left: 0;
    width: 100vw;
    overflow: hidden;

    @include respond-below(sm) {
        height: $footer-height-sm;
        max-height: $footer-height-sm;
    }

    @include respond-below(xs) {
        height: $footer-height-xs;
        max-height: $footer-height-xs;
    }

    @include respond-landscape-below(sm) {
        height: $footer-height-sm-landscape;
    }

    .footer-image {
        position: absolute;
        top: 0;
        left: 0;
        background-size: cover;
        background-position: center;
        height: 100%;
        width: 100%;
        //filter: blur(4.5px);
    }

    .footer-content {
        z-index: 1;
        // height: 40%;
        width: 100%;
        max-width: $main-max-width;
        // margin-top: 65px;
        margin: 25px 0;

        @include respond-below(xl) {
            max-width: 90%;
        }

        @include respond-below(sm) {
            // height: 82%;
            width: 82%;
            // margin-top: 22px;
            margin: 5px 0;
        }

        .footer-content-row {
            width: 100%;
            flex-basis: 45%;

            &:first-child {
                margin-bottom: 10px;
            }

            &.top {
                align-items: flex-start;
            }

            &.bottom {
                align-items: flex-end;
            }

            @include respond-below(sm) {
                flex-wrap: wrap;
            }

            .footer-content-col {
                @include respond-below(sm) {
                    flex-basis: 100% !important;
                }
            }

            .footer-logos {
                flex-basis: 15.5%;

                @include respond-below(sm) {
                    order: 1;
                }

                .footer-logo {
                    &.wom {
                        height: 80px;
                        width: 120px;

                        @include respond-below(sm) {
                            height: 56px;
                            width: 103px;
                        }
                    }

                    &.brown {
                        height: 80px;
                        width: 80px;
                        filter: invert(100%);

                        @include respond-below(sm) {
                            height: 60px;
                            width: 60px;
                        }
                    }

                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                }
            }

            .footer-links {
                flex-basis: 74%;
                font-weight: bold;
                letter-spacing: 0.1rem;
                justify-content: flex-start;

                @include respond-below(sm) {
                    justify-content: space-between;
                }

                .footer-link {
                    text-decoration: none;
                    color: $dark-color;
                    text-shadow: none;
                    transition: text-shadow $nav-link-transition-speed;
                    margin: 0 25px 20px 0;

                    @include respond-below(sm) {
                        margin: 0 10px 15px 0;
                    }

                    @include respond-below(xs) {
                        margin: 0 5px 15px 0;
                    }
                }

                .footer-link:hover {
                    text-shadow: 0 0 1px $dark-color;
                }

            }

            .social-links {
                flex-basis: 15.5%;

                .social-icon {
                    height: 30px;
                    width: 30px;
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                    margin: 0 10px 10px 0;
                    transition: filter ease-in $nav-link-transition-speed;

                    &:hover {
                        filter: drop-shadow(0.5px 0.5px 0.5px rgba(0, 0, 0, 1));
                    }
                }
            }

            .footer-copyrights {
                flex-basis: 74%;

                @include respond-below(sm) {
                    order: 2;
                }

                @include respond-below(xs) {
                    font-size: 10px;
                }
            }
        }
    }
}

// =========================
//     Pages Components
// =========================

// ==========================
//          Page
// ==========================

.page {
    display: flex;
    flex-flow: column nowrap;
    max-width: $screen-max-width;
}


// ==========================
//      HeadlineWithText
// ==========================
.headline-with-text {
    text-align: center;

    .headline {
        transition: font-size 200ms;
        margin: 0 0 30px 0;
    }

    .text {
        width: 100%;
        line-height: 1.67;
        transition: width 200ms, font-size 200ms;
        padding: 0 10%;
        text-align: justify;
    }

    @include respond-below(sm) {
        .headline {
            margin: 0 0 20px 0;
        }

        .text {
            line-height: 1.29;
            padding: 0;
        }
    }
}

// =================
//     Paragraph
// =================

.paragraph-section {
    text-align: center;
}

// =========================
//     TwoImagesWithLabel
// =========================

.two-images-with-label {
    position: relative;

    .single-image-container {
        position: absolute;
        width: 48%;
        height: 89%;
        overflow: hidden;
        cursor: default;

        &.right {
            bottom: 0;
            right: 0;
        }

        &.left {
            top: 0;
            left: 0;
        }

        .single-image {
            transition: transform ease-in $zoom-transition-speed,
            opacity ease-in $zoom-transition-speed;

            @include background-image-cover(100%, 100%);

            &:hover {
                transform: $image-zoom-in-scale;
                opacity: $image-opacity-hover;
            }
        }
    }

    .label-container {
        align-self: center;
        position: absolute;
        box-shadow: 0 8.5px 15.5px 0 rgba(0, 0, 0, 0.16);
        background: $text-light;
        overflow: hidden;

        .label {
            height: 100%;
            width: 100%;
            z-index: 1;
            align-self: center;
            padding: 1.77rem;
            font-family: 'Headlines-Font', sans-serif;
            transition: transform ease-in $zoom-transition-speed,
            opacity ease-in $zoom-transition-speed;

            &:hover {
                transform: scale(1.05, 1.05);
            }
        }

        @include target-ie-browser() {
            /* IE10+ specific styles go here */
            position: relative;
        }
    }

    &.locations {
        width: 100%;
        min-height: 204px;
        min-width: 306px;
        margin-bottom: 43.5px;
        cursor: pointer;

        * {
            cursor: pointer;
        }

        @include keep-ratio(229px, 638.5px);

        &:last-child {
            margin-bottom: 0;
        }

        .label-container {
            min-width: 24.5%;
            max-width: 85%;
            height: 34.9%;

            .label {
                text-align: center;
                color: $dark-color;
            }
        }

        @include respond-below(sm) {
            min-height: 111px;
            min-width: 167.5px;
            margin-bottom: 9.5px;

            @include keep-ratio(111px, 167.5px);

            .single-image-container {
                height: 100%;
                width: 100%;

                &.right {
                    display: none;
                }

                &.left {
                    align-self: center;
                    margin-right: 0;
                }
            }

            .label-container {
                min-width: 46.26%;
                height: 34.68%;
            }
        }
    }


    &.carousel {
        height: 100%;
        width: 100%;
        min-height: 176.5px;
        min-width: 265px;
        cursor: default;

        .label-container {
            width: 34.8%;
            height: 55.5%;

            @include respond-below(sm) {
                width: 93%;
                height: 38.1%;

                .label {
                    font-size: 1.6rem;
                    text-align: left;
                }
            }

            .carousel-label-content {
                padding: 10% 9%;
                text-align: left;
                height: 100%;
                width: 100%;

                @include respond-below(xl) {
                    padding: 5% 6%;
                }

                @include respond-below(sm) {
                    padding: 7% 6%;
                }

                .carousel-label-title {
                    height: 50%;

                    @include respond-below(sm) {
                        //margin-bottom: 15px;
                    }

                    .item-title {
                        font-size: 2rem;
                        line-height: 1;
                        margin-bottom: 10px;

                        @include respond-below(xl) {
                            font-size: 1.6rem;
                        }

                        @include respond-below(md) {
                            font-size: 1.4rem;
                        }

                        @include respond-below(sm) {
                            font-size: 2.4rem;
                        }

                        @include respond-below(xs) {
                            font-size: 2.2rem;
                        }

                        @include respond-below(xxs) {
                            font-size: 1.7rem;
                        }
                    }
                }

                .carousel-label-footer {
                    .item-location {
                        height: 25%;

                        @include respond-below(sm) {
                            margin-bottom: 5px;
                        }

                        .item-location-icon {
                            margin-right: 2px;
                            @include background-image-contain(25px, 16px);

                            @include respond-above(lg) {
                                @include background-image-contain(28px, 19px);
                            }
                        }

                        .item-location-label {
                            font-size: 0.9rem;

                            @include respond-above(lg) {
                                font-size: 1.1rem;
                            }

                            @include respond-below(lg) {
                                font-size: 1.1rem;
                            }

                            @include respond-below(sm) {
                                font-size: 1.3rem;
                            }
                        }
                    }

                    .carousel-label-link {
                        height: 25%;

                        .item-link {
                            z-index: 3;
                            transition: color $nav-link-transition-speed;
                            cursor: pointer;
                            font-size: 1rem;

                            @include respond-above(xl) {
                                font-size: 0.9rem;
                            }

                            @include respond-below(sm) {
                                font-size: 1.2rem;
                            }

                            &:hover {
                                color: $dark-color;
                            }
                        }
                    }
                }
            }
        }

        @include respond-below(sm) {
            max-width: 100%;
            min-width: 144px;

            .single-image-container {
                width: 100%;
                height: 47.4%;
            }
        }
    }
}

// ===================
//      Locations
// ===================

.locations-section {
    .locations {
        width: 100%;
    }
}

// ======================
//      DetailsHoverGrid
// ======================

.details-hover-grid-section {
    .details-hover-grid {
        width: 100%;

    }
}

// ======================
//      DetailsHoverGrid
// ======================

.details-hover {
    display: flex;
    flex-basis: calc(50% - 10px);
    margin-bottom: 20px;
    min-height: 166.5px;
    min-width: 314px;
    align-items: center;
    justify-content: center;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: default;
    transition: width 100ms, height 100ms;

    @include keep-ratio(166.5px, 314px);

    @include respond-below(sm) {
        min-width: 250px;
    }

    &:hover .label {
        display: none;
    }

    &:hover .details {
        opacity: 1;
    }

    .label {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        align-self: center;
        min-width: 32.6%;
        max-width: 80%;
        height: 35.1%;
        //max-height: 112px;
        max-height: 150px;
        background: $background-color;
        box-shadow: 0 8.5px 13.5px 0 rgba(0, 0, 0, 0.16);
        transition: width 100ms, height 100ms;
        padding: 22px 16.5px;

        @include respond-below(sm) {
            //max-height: 83px;
        }

        .label-title {
            @include respond-below(xl) {
                font-size: 1.5rem;
            }
        }
    }

    .details {
        position: absolute;
        bottom: 0;
        text-align: center;
        height: 100%;
        width: 100%;
        background: rgba($light-color, 0.7);
        opacity: 0;
        transition: opacity ease-in 0.2s;
        overflow: hidden;
        padding: 5% 5%;

        @include respond-below(xl) {
            padding: 5% 5%;
        }

        @include respond-below(md) {
            padding: 2% 6%;
        }

        @include respond-below(sm) {
            padding: 18% 6%;
        }

        @include respond-below(xxs) {
            padding: 7% 6%;
        }

        .details-title {
            margin-bottom: 13.5px;
        }

        .details-paragraph {
            text-align: left;

            @include respond-below(xl) {
                font-size: 0.9rem;
            }

            @include respond-below(sm) {
                font-size: 1rem;
            }
        }
    }

    @include respond-below(sm) {
        flex-basis: 100%;
        margin-bottom: 20px;

        &:last-of-type {
            margin: 0;
        }

        @include keep-ratio(119px, 167.5px);

        .label {
            min-width: 43.5%;
            max-width: 90%;
            height: 34.8%;
            padding: 21px 13.5px;
        }
    }
}

// ===================
//        WOMs
// ===================

.woms {
    .woms-content {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        //height: 100%;

        .hide-background {
            @include hide-background($background-color);
        }

        .woms-headline {
            position: absolute;
            flex-basis: 100%;
            z-index: 2;
        }

        .woms-section {
            &.left {
                background-color: #f9e15d;;
                padding: 4% 4% 58px 13.5%;
                flex-basis: 50.05%;

                @include respond-below(xxl) {
                    padding: 4% 4% 58px 8.2%;
                }

                @include respond-below(xl) {
                    padding: 4% 4% 58px 3%;
                }

                @include respond-below(sm) {
                    flex-basis: 100%;
                    padding: 10% 6% 8%;
                }

                .woms-explanation {
                    .ordered-list {
                        margin-bottom: 45px;
                        text-align: left;

                        @include respond-below(sm) {
                            margin-bottom: 35px;
                        }

                        .single-number-text {
                            flex-basis: calc(25% - 2px);

                            .number-text-number {
                                font-size: 3rem;
                                margin-right: 1.2rem;
                                // font-family: Headlines-Font, sans-serif;

                                @include respond-below(xl) {
                                    font-size: 2.2rem;
                                }
                            }

                            .number-text-text {
                                font-size: 1.333rem;

                                @include respond-below(xl) {
                                    font-size: 1rem;
                                }
                            }
                        }
                    }

                    .public-spaces {
                        text-align: justify;
                        margin-bottom: 30px;

                        @include respond-below(sm) {
                            margin-bottom: 25px;
                        }

                        .public-title {
                            margin-bottom: 3px;
                            font-size: 1.333rem;

                            @include respond-below(xl) {
                                font-size: 1.2rem;
                            }
                        }

                        .public-text {
                            text-align: justify;
                            font-size: 1.333rem;

                            @include respond-below(xl) {
                                font-size: 1rem;
                            }
                        }
                    }

                    a {
                        color: $light-color;
                    }
                }

                .learn-more-link {
                    z-index: 10;

                    &:hover {
                        color: $theme-color-6;
                    }
                }
            }

            &.right {
                //padding: 0 13.5% 0 0;
                flex-basis: 49.95%;

                @include respond-below(xxl) {
                    //padding: 0 8.2% 0 0;
                }

                @include respond-below(xl) {
                    //padding: 0 3% 0 0;
                }

                @include respond-below(sm) {
                    flex-basis: 100%;
                    //padding: 0;
                    min-height: 500px;
                }

                @include respond-below(xs) {
                    flex-basis: 100%;
                    //padding: 0;
                    min-height: 350px;
                }

                //.image-container {
                //    position: relative;
                //    height: 100%;
                //    width: 100%;
                //    top: 0;
                //    left: 0;
                //    z-index: 4;
                //    justify-content: center !important;

                .image {
                    @include background-image-contain(auto, 100%);

                    @include respond-below(sm) {

                    }
                }

                //}
            }
        }
    }
}

// ===================
//      Subscribe
// ===================

.subscribe-section {
    .subscribe-title {
        margin-bottom: 2rem;
    }

    .subscribe-subtitle {
        margin-bottom: 2.8rem;
    }

    &.done {
        .subscribe-line {
            height: auto;

            path {
                stroke-dashoffset: -762;
                stroke-dasharray: 1095, 1903;
                transition: stroke-dasharray 1700ms, stroke-dashoffset 0ms, opacity 1ms;
                opacity: 1;
            }
        }

        .subscribe-form {
            display: none;
        }
    }

    .subscribe-form {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 1rem;
        width: 100%;

        @include respond-below(xl) {
            width: 85%;
        }

        @include respond-below(md) {
            width: 90%;
        }

        .subscribe-input {
            border: none;
            width: 66.8%;
            height: 65px;
            border-bottom: 1px solid $theme-color-4;
            // padding-bottom: 0.8rem;
            margin-right: 2.5rem;
            background: transparent !important;

            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: $theme-color-4;
            }

            &::-moz-placeholder { /* Firefox 19+ */
                color: $theme-color-4;
            }

            &:-ms-input-placeholder { /* IE 10+ */
                color: $theme-color-4;
            }

            &:-moz-placeholder { /* Firefox 18- */
                color: $theme-color-4;
            }
        }

        .subscribe-input:focus {
            outline-width: 0;
        }
    }

    .subscribe-line {
        left: 0;
        position: relative;
        width: 340px;
        height: 0;

        path {
            fill: none;
            stroke: black;
            stroke-width: 2;
            stroke-dasharray: 300, 1903;
            opacity: 0;
        }
    }

    .subscribe-line2 {
        height: 52px;
        left: 150px;
        opacity: 0;
        position: relative;
        stroke-dasharray: 6, 48;
        stroke-dashoffset: -42;
        top: 158px;
        transform-origin: 50%;
        width: 50px;
        display: none;

        path {
            fill: none;
            stroke: #000000;
            stroke-width: 2
        }
    }

    .subscribe-form {
        transform-origin: 0 50%;
        transition: transform 500ms, opacity 1ms;
    }

    .subscribe-input {
        background: transparent;
        border: 0;
        box-sizing: border-box;
        font-size: 20px;
        height: 40px;
        margin-top: 20px;
        outline: none !important;
        position: relative;
        width: 300px;
    }

    //@keyframes Rotate {
    //    0% { transform: rotate(0); opacity: 1; }
    //    25% { transform: rotate(360deg); opacity: 1; }
    //    50% { transform: rotate(720deg); opacity: 1; }
    //    75% { transform: rotate(1080deg); opacity: 1; stroke-dasharray: 6, 48; stroke-dashoffset: -42;}
    //    100% { transform: rotate(1080deg); opacity: 1; stroke-dashoffset: 0; stroke-dasharray: 36, 48;}
    //}
    //@keyframes ScaleDown {
    //    0% { transform: scale(1); }
    //    100% { transform: scale(0);}
    //}


    @include respond-below(sm) {
        margin: 3.5rem 0 5rem;

        .subscribe-form {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;

            .subscribe-input {
                width: 89.3%;
                margin-right: 0;
                margin-bottom: 30px;
                font-size: 1.2rem;
            }
        }

        .subscribe-title {
            margin-bottom: 1.5rem;
        }

        .subscribe-subtitle {
            margin-bottom: 2rem;
        }
    }

    @include respond-below(xxs) {
        margin: 3.5rem 0 5rem;

        .subscribe-title {
            font-size: 3rem;
        }

        .subscribe-subtitle {
            font-size: 1rem;
        }
    }
}

// ========================
//      ImageCarousel
// ========================

.multi-slide-carousel {
    .multi-slide {
        min-width: 250px;

        @include keep-ratio(299.5px, 528.5px);

        @include respond-below(sm) {
            @include keep-ratio(162px, 288px);
        }

        .slider-frame {
            height: auto !important;

            .slider-list {
                user-select: none;
            }
        }

        .one-image-slide {
            background-size: cover;
            background-position: center;
            //min-width: 350px;

            @include keep-ratio(299.5px, 528.5px);

            @include respond-below(sm) {
                @include keep-ratio(162px, 288px);
            }
        }

        @include carousel-arrow($carousel-arrow-color, $dark-color);

        .slider-control-centerright {
            right: -3% !important;
            background-color: rgba(255, 255, 255, 0.0000001);
            z-index: 10;

            @include respond-below(xl) {
                right: -5.5% !important;
            }

            @include respond-below(lg) {
                right: -4.2% !important;
            }

            @include respond-below(md) {
                right: -6% !important;
            }

            @include respond-below(sm) {
                right: -3% !important;
            }

            @include respond-below(xs) {
                right: -4% !important;
            }

            @include respond-below(xxs) {
                right: -6.5% !important;
            }
        }

        .slider-control-centerleft {
            left: -3% !important;
            background-color: rgba(255, 255, 255, 0.0000001);
            z-index: 10;

            @include respond-below(xl) {
                left: -5.5% !important;
            }

            @include respond-below(lg) {
                left: -4.2% !important;
            }

            @include respond-below(md) {
                left: -6% !important;
            }

            @include respond-below(sm) {
                left: -3% !important;
            }

            @include respond-below(xs) {
                left: -4% !important;
            }

            @include respond-below(xxs) {
                left: -6.5% !important;
            }
        }

        .slider-slide {
            opacity: 0.6;
            transition: opacity 0.6s !important;
            cursor: default;

            &.slide-visible {
                opacity: 1;
                overflow: hidden;

                .one-image-slide {
                    transition: transform $zoom-transition-speed !important;

                    @include hover-supported() {
                        transform: scale(1.15, 1.15);
                    }
                }


            }
        }
    }
}

// ============================
//  TwoImagesWithLabelCarousel
// ============================

.two-images-with-label-carousel-section {
    position: relative;
    min-width: 300px;
    width: 100%;

    .two-images-with-label-carousel-hide-background {
        @include hide-background($background-color);
    }

    .section-title {
        z-index: 2;

        @include respond-below(sm) {
            margin: 0 0 30px 0;
        }
    }

    .two-images-with-label-carousel {
        height: 450px !important;
        width: 60% !important;
        margin-bottom: 50px;
        z-index: 2;

        @include respond-below(xl) {
            height: 300px !important;
        }

        @include respond-below(md) {
            height: 250px !important;
        }

        @include respond-below(sm) {
            height: 700px !important;
            max-width: 80%;
        }

        @include respond-below(xs) {
            height: 550px !important;
        }

        @include respond-below(xxs) {
            height: 400px !important;
            max-width: 75%;
        }


        .slider-list {
            height: 100% !important;
        }

        .slider-slide {
            height: 100% !important;
        }


        @include carousel-arrow($carousel-arrow-color, $dark-color);

        .slider-control-centerright {
            right: -3% !important;

            @include respond-below(sm) {
                right: -5% !important;
            }

            @include respond-below(xxs) {
                right: -10% !important;
            }
        }

        .slider-control-centerleft {
            left: -3% !important;

            @include respond-below(sm) {
                left: -5% !important;
            }

            @include respond-below(xxs) {
                left: -10% !important;
            }
        }
    }

    .two-images-with-label-link-container {
        margin-bottom: 80px;
        z-index: 2;
        cursor: pointer;

        @include respond-below(sm) {
            margin-bottom: 30px;
        }

        .two-images-with-label-link {
            transition: color $nav-link-transition-speed;


            &:hover {
                color: $dark-color !important;
            }

            &.text-light {
                color: $light-color;
            }

            &.text-color {
                color: $theme-color-1;
            }

            @include respond-below(sm) {
                font-size: 1.3rem;
            }
        }


    }


    //position: relative;
    //height: $single-slide-carousel-container-height;
    //justify-content: space-between;
    //
    //&.on-background {
    //    .carousel-link {
    //        a {
    //            color: $light-color;
    //        }
    //    }
    //
    //    .arrow {
    //        border: solid $carousel-arrow-color-light;
    //    }
    //
    //}
    //
    //&.not-on-background {
    //    .carousel-link {
    //        a {
    //            color: $theme-color-1;
    //        }
    //    }
    //
    //    .arrow {
    //        border: solid $carousel-arrow-color;
    //    }
    //
    //}
    //
    //.single-slide {
    //    height: 60% !important;
    //}
    //
    //.slider-list {
    //    height: 100% !important;
    //}
    //
    //.slider-slide {
    //    height: 100% !important;
    //}
    //
    //.background-color {
    //    position: absolute;
    //    top: 0;
    //    left: 0;
    //    width: 100%;
    //    height: 100%;
    //    background-image: linear-gradient(
    //                    $background-color 10%,
    //                    transparent 32%,
    //                    transparent 100%
    //    );
    //}
    //
    //.carousel-link {
    //    text-decoration: none;
    //    font-size: 1rem;
    //    margin: 3rem;
    //    z-index: 1;
    //    letter-spacing: 0.5px;
    //
    //    a {
    //        transition: color ease-in 200ms;
    //    }
    //
    //    &:hover {
    //        a {
    //            color: $dark-color;
    //        }
    //    }
    //}
    //
    //.single-slide {
    //    .arrow {
    //        border-width: 0 1px 1px 0;
    //        height: 26px;
    //        width: 21px;
    //        display: inline-block;
    //        padding: 13px;
    //        cursor: pointer;
    //
    //        transition: border-color 200ms;
    //
    //        &:hover {
    //            border-color: $dark-color;
    //        }
    //
    //        &.right {
    //            transform: rotate(-45deg);
    //        }
    //
    //        &.left {
    //            transform: rotate(135deg);
    //        }
    //    }
    //
    //    .slider-control-centerright {
    //        right: -3.5rem !important;
    //    }
    //
    //    .slider-control-centerleft {
    //        left: -3.5rem !important;
    //    }
    //
    //    .slider-slide {
    //        opacity: 0.6;
    //        transition: opacity 0.6s !important;
    //
    //        &.slide-visible {
    //            opacity: 1;
    //            overflow: hidden;
    //
    //            img {
    //                transition: transform $zoom-transition-speed !important;
    //            }
    //        }
    //
    //        &.slide-visible:hover img {
    //            transform: scale(1.23, 1.23);
    //        }
    //    }
    //}
    //
    ////@include respond-below(xl) {
    ////  .single-slide {
    ////    width: 60% !important;
    ////  }
    ////}
    ////
    ////@include respond-below(lg) {
    ////  .single-slide {
    ////    width: 65% !important;
    ////  }
    ////}
    ////
    ////@include respond-below(md) {
    ////  .single-slide {
    ////    width: 70% !important;
    ////  }
    ////}
    //
    //@include respond-below(sm) {
    //    height: $single-slide-carousel-container-height-mobile;
    //    justify-content: flex-end;
    //
    //    .section-title-overlap {
    //        transform: translate(0, -12%);
    //    }
    //
    //    .carousel-link {
    //        margin: 1.5rem;
    //        font-size: 1.7rem;
    //    }
    //
    //    .single-slide {
    //        height: 80% !important;
    //        width: 70% !important;
    //
    //        img {
    //            height: $single-slide-carousel-container-height-mobile !important;
    //        }
    //
    //        .slider-slide {
    //            height: 100% !important;
    //        }
    //
    //        .slider-frame {
    //            //height: $single-slide-carousel-height-mobile !important;
    //        }
    //
    //        .slider-list {
    //            //height: 37rem !important;
    //        }
    //    }
    //}
    //
    //@include respond-below(xs) {
    //
    //    .section-title-overlap {
    //        transform: translate(0, -31%);
    //    }
    //}
}

// ============================
//  HotelPublicPlacesCarousel
// ============================

.hotel-public-places-carousel-container {
    //* {
    //    box-sizing: border-box;
    //    border: 1px solid lightcoral;
    //}

    min-width: 305px;


    .hotel-public-places-carousel {

        @include keep-ratio(630px, 1102px);

        @include carousel-arrow($dark-color, $carousel-arrow-color);

        .slider-frame {
            height: auto !important;
        }

        .slider-control-centerright {
            right: -7.8% !important;
        }

        .slider-control-centerleft {
            left: -7.8% !important;
        }


        @include respond-below(sm) {
            @include keep-ratio(271px, 374px);
        }
    }
}

// ===================
//  HotelPublicPlaces
// ===================

.hotel-public-places {
    height: 100%;
    width: 100%;
    position: relative;

    @include keep-ratio(630px, 1102px);

    .background-image {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center;
    }

    .book-btn {
        position: absolute;
        bottom: 30%;
        z-index: 1;
    }


    @include respond-below(sm) {
        @include keep-ratio(271px, 374px);

        .book-btn {
            bottom: 10.33%;
        }
    }

    @include respond-landscape-below(lg) {
        .book-btn {
            bottom: 10.33%;
        }
    }
}


// ===================
//      HeaderHome
// ===================

.header-home {
    width: 100vw;
    max-width: 1920px;
    //height: 94.5vh;
    height: 95.5vh;
    max-height: 929px;
    min-height: 320px;
    position: relative;
    margin-top: -$navbar-height;
    margin-bottom: $header-home-margin-bottom;


    //@include keep-ratio(9px, 16px);

    @include respond-below(xl) {
        margin-bottom: $header-home-margin-bottom-xl;
    }

    @include respond-below(lg) {
        margin-bottom: $header-home-margin-bottom-lg;
    }

    @include respond-below(md) {
        margin-bottom: $header-home-margin-bottom-md;
        height: 100vh;
    }

    .background-container {
        position: relative;
        height: 96.5%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        min-height: 350px;
        overflow: hidden;
        background-color: $dark-color;

        @include respond-below(md) {
            height: 50vh;
        }

        @include respond-below(xxs) {
            height: 45vh;
        }

        .background-image {
            @include background-image-cover(100%, 100%);
        }

        .background-slider {
            z-index: 1;
            width: 100%;
            height: 100%;
            overflow: hidden;
        }

        .logo-and-subtitle {
            position: absolute;
            width: 70%;
            height: 50%;
            z-index: 2;
            right: calc(50% - (70% / 2));
            margin: 0;
            bottom: 30%;

            .logo {
                //width: 90%;
                width: 764px;
                height: 154px;
                margin-bottom: 60px;

                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                //filter: drop-shadow(6px 11px 9px rgba(0, 0, 0, 0.5));

                @include respond-below(md) {
                    margin-bottom: 10px;
                    width: 553px;
                    height: 180px;
                }

                @include respond-below(sm) {
                    width: 465px;
                    height: 122px;
                }

                @include respond-below(xs) {
                    width: 356px;
                    height: 144px;
                }

                @include respond-below(xxs) {
                    margin-bottom: 0;
                    width: 320px;
                    height: 150px;
                }

                @include respond-landscape-below(md) {
                    margin-bottom: 20px;
                    width: 400px;
                    height: 150px;
                }

                @include respond-landscape-below(sm) {
                    margin-bottom: 20px;
                    width: 320px;
                    height: 150px;
                }
            }

            .subtitle {
                width: 100%;
                text-align: center;
                font-size: 2.5rem;
                font-weight: normal;
                color: $text-light;
                text-shadow: 0 4.5px 13.5px rgba(0, 0, 0, 0.9);
                transition: bottom $top-nav-logo-transition-speed,
                width $top-nav-logo-transition-speed,
                font-size 200ms;
                margin: 0;
                white-space: pre-line;

                @include respond-below(sm) {
                    font-size: 3.1rem;
                }

                @include respond-below(xs) {
                    font-size: 2.7rem;
                }

                @include respond-below(xxs) {
                    font-size: 2rem;
                }

                @include respond-landscape-below(sm) {
                    font-size: 2.2rem;
                }
            }

            @include respond-below(md) {
                width: 94%;
                right: calc(50% - (94% / 2));
                bottom: 20%;
            }

            @include respond-below(xs) {
                bottom: 30%;
            }

            @include respond-below(xxs) {
                bottom: 30%;
            }
        }
    }

    @include respond-below(md) {
        height: auto;
        min-height: 491px;
        margin-bottom: $header-home-margin-bottom-sm;
    }

    @include respond-below(xs) {
        //height: 100vh;
        //min-height: 401px;
        margin-bottom: $header-home-margin-bottom-xs;
    }

    @include respond-below(xxs) {
        //min-height: 140vh;
        ////min-height: 401px;
        //margin-bottom: $header-home-margin-bottom-xs;
    }
}

// =====================
//   BackgroundSlider
// =====================

.background-slider-container {
    position: relative;
    width: 100%;
    height: 100%;

    .background-slide {
        @include background-image-cover(100%, 100%);
        transition: 500ms;
        position: absolute;
        top: 0;
        left: 0;
        display: block;

        &.show {
            opacity: 1;
        }

        &.hide {
            //display: none;
            opacity: 0;
        }
    }
}

// =====================
//   InstagramPhotoGrid
// =====================

.instagram-photo-grid-section {
    .section-title {
        margin-bottom: 36px;

        @include respond-below(sm) {
            margin-bottom: 32px;
        }
    }

    .instagram-button {
        margin-bottom: 50px;

        @include respond-below(sm) {
            margin-bottom: 30px;
        }
    }

    .instagram-photo-grid {
        width: 100%;

        .instagram-photo-container {
            flex-basis: calc(33% - 10px);
            position: relative;
            margin-bottom: calc(1% + 10px);

            @include keep-ratio(412px, 410px);

            @include respond-below(sm) {
                flex-basis: calc(50% - 5px);
                margin-bottom: 10px;

                @include keep-ratio(162px, 162px);


                &:nth-child(n+5) {
                    display: none;
                }
            }

            .instagram-photo {
                position: absolute;
                top: 0;
                left: 0;

                @include background-image-cover(100%, 100%);
            }
        }
    }
}

// ========================
//    HeaderTwoParagraphs
// ========================

.header-two-paragraphs-section {
    position: relative;
    //box-sizing: border-box;
    //border: 1px dashed crimson;

    //* {
    //  box-sizing: border-box;
    //  border: 1px dashed crimson;
    //}

    .header-content {
        width: 100%;
        //background-color: $theme-color-2;

        .two-paragraphs-header-headline {
            flex-basis: 100%;
            margin: 30px 0 30px;


            .section-title {
                margin: 0;
            }

            @include respond-below(sm) {
                margin: 15px 0 15px;
            }
        }

        .header-paragraph-container {
            flex-basis: 82.26%;
            z-index: 5;
            margin-bottom: 80px;

            .header-paragraph {
                text-align: justify;
            }

            @include respond-below(sm) {
                flex-basis: 89.6%;
                margin-bottom: 50px;

                .header-paragraph {
                    flex-basis: 100%;
                }
            }
        }
    }
}

// ========================
//     CollectionGallery
// ========================

.collection-gallery-section {
    .collection-gallery {
        width: 100%;
    }
}

// ==========================
//     CollectionGalleryItem
// ==========================

.collection-gallery-item-container {
    position: relative;
    margin: 0 23px 72px 0;
    flex-basis: calc(33% - 23px);

    @include keep-ratio(540px, 412px);

    @include respond-below(sm) {
        min-width: 250px;
        flex-basis: 80%;
        margin: 0 0 61px;

        @include keep-ratio(424px, 299px);
    }

    .collection-gallery-item {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        &:hover .gallery-item-label .gallery-item-label-background {
            opacity: 1;
        }

        .gallery-item-image-container {
            position: absolute;
            top: 0;
            left: 0;
            height: 66.11%;
            width: 86.9%;
            overflow: hidden;
            z-index: 1;

            @include respond-below(sm) {
                width: 96%;
                height: 67%;
            }

            .gallery-item-image {
                @include background-image-cover(100%, 100%);
                transition: transform ease-in $zoom-transition-speed;

                &:hover {
                    transform: scale(1.10, 1.10);
                }
            }
        }

        .gallery-item-label {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 95.1%;
            height: 46.5%;
            background-color: #fff;
            box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
            z-index: 10;
            color: $text-dark;

            @include respond-below(sm) {
                width: 90%;
                height: 40%;
            }

            .gallery-item-label-background {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
                z-index: 0;
                background-color: #ffffff;
                opacity: 0;
                transition: opacity $opacity-transition-speed;
            }

            .gallery-item-label-content {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                padding: 6.5% 10px 10px;
                z-index: 2;
                text-align: left;

                @include respond-below(sm) {
                    padding: 5% 5px 31px 27px;
                }

                .gallery-item-label-title {
                    height: 50%;

                    @include respond-below(sm) {
                        margin-bottom: 15px;
                    }

                    .item-title {
                        font-size: 30px;
                        margin-bottom: 10px;
                        line-height: 1.13;

                        @include respond-below(xl) {
                            font-size: 23px;
                        }

                        @include respond-below(md) {
                            font-size: 20px;
                        }

                        @include respond-below(sm) {
                            font-size: 31px;
                        }

                        @include respond-below(xs) {
                            font-size: 23px;
                        }
                    }
                }
            }

            .gallery-item-label-footer {
                .item-location {
                    height: 25%;

                    .item-location-icon {
                        margin-right: 2px;
                        @include background-image-contain(25px, 16px);
                    }

                    .item-location-label {
                        font-size: 0.9rem;

                        @include respond-below(sm) {
                            font-size: 1.3rem;
                        }
                    }
                }

                .gallery-item-label-link {
                    height: 22%;

                    @include respond-below(xs) {
                        height: 6%;
                    }

                    .item-link {
                        z-index: 3;
                        transition: color $nav-link-transition-speed;
                        cursor: pointer;
                        font-size: 1rem;

                        @include respond-below(xl) {
                            font-size: 0.9rem;
                        }

                        @include respond-below(sm) {
                            font-size: 1.2rem;
                        }

                        &:hover {
                            color: $dark-color;
                        }
                    }
                }
            }
        }
    }
}

// =====================
//   header-woms
// =====================

.header-woms {
    .header-woms-content {
        width: 100%;

        .headline {
            margin: 30px 0 30px;

            @include respond-below(sm) {
                margin: 15px 0;
            }
        }

        .intro-text {
            width: 82.26%;
            transition: width 0.2s, font-size 0.2s;
            text-align: justify;
            margin-bottom: 2.5rem;
            align-self: center;
            line-height: 1.67;

            @include respond-below(sm) {
                width: 78.34%;
                margin-bottom: 27px;
            }
        }

        .intro-image-container {
            width: 82.81%;
            overflow: hidden;

            @include respond-below(sm) {
                width: 89.3%;
            }

            .intro-image {
                width: 100%;
                background-size: cover;
                background-position: center;
                transition: transform $zoom-transition-speed;

                &:hover {
                    transform: $image-zoom-in-scale;
                }

                @include keep-ratio(558px, 1060px);

                @include respond-below(sm) {
                    @include keep-ratio(419px, 334px);
                }
            }
        }
    }
}

// =====================
//   woms-description
// =====================

.woms-description {
    max-width: $main-max-width;
    position: relative;

    //* {
    //    border: 1px dashed red;
    //    box-sizing: content-box;
    //}

    .woms-description-content {
        width: 100%;

        .woms-description-hide-background {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 1;
            background-image: linear-gradient(
                            $background-color 50px,
                            transparent 50px,
                            transparent 75%,
                            $background-color 75%,
                            $background-color 100%
            );

            @include respond-below(md) {
                background-image: linear-gradient(
                                $background-color 39px,
                                transparent 39px,
                                transparent 75%,
                                $background-color 75%,
                                $background-color 100%
                );
            }

            @include respond-below(sm) {
                background-image: linear-gradient(
                                $background-color 32px,
                                transparent 32px,
                                transparent 59.5%,
                                $background-color 59.5%,
                                $background-color 100%
                );
            }

            @include respond-below(xs) {
                background-image: linear-gradient(
                                $background-color 25px,
                                transparent 25px,
                                transparent 60%,
                                $background-color 60%,
                                $background-color 100%
                );
            }

            @include respond-below(xxs) {
                background-image: linear-gradient(
                                $background-color 20px,
                                transparent 20px,
                                transparent 61%,
                                $background-color 61%,
                                $background-color 100%
                );
            }

            @include respond-landscape-below(sm) {
                background-image: linear-gradient(
                                $background-color 25px,
                                transparent 25px,
                                transparent 75%,
                                $background-color 75%,
                                $background-color 100%
                );
            }
        }

        .woms-description-headline {
            flex-basis: 100%;
            margin: 0 0 90px;
            transition: margin-top 0.2s;
            text-align: center;
            z-index: 3;

            .section-title {
                margin: 0;
            }

            @include respond-below(sm) {
                margin: 0 0 22px;
            }
        }

        .wom-features-container {
            width: 71%;
            transition: width 0.2s, font-size 0.2s;
            margin-bottom: 91px;

            @include respond-below(sm) {
                margin-bottom: 26px;
                width: 89.83%;
            }

            .wom-features-headline {
                font-size: 2rem;
                text-align: left;
                margin-bottom: 1.5rem;
            }

            .wom-features-list {
                text-align: left;
                font-size: 1.333rem;
                font-family: 'Headlines-Font', sans-serif;
                line-height: 2;

                ul {
                    column-count: 2;
                    list-style-position: inside;
                }

                * {
                    margin: 0;
                    padding: 0;
                }

                @include respond-below(sm) {
                    ul {
                        column-count: 1;
                    }
                }
            }

            .wom-features-list-item {
                transition: font-size 0.2s;
                margin-bottom: 0.5rem;
            }
        }

        .wom-image-container {
            //width: 82.81%;
            width: 100%;
            z-index: 5;
            overflow: hidden;

            @include respond-below(sm) {
                //width: 89.3%;
            }

            //.wom-image {
            //    height: 100%;
            //    width: 100%;
            //    background-size: cover;
            //    background-position: center;
            //    transition: transform $zoom-transition-speed;
            //
            //    &:hover {
            //        transform: $image-zoom-in-scale;
            //    }
            //
            //
            //    @include keep-ratio(558px, 1060px);
            //
            //    @include respond-below(sm) {
            //        @include keep-ratio(419px, 334px);
            //    }
            //}
        }


        .wom-description-btn {
            margin-top: 59px;
            flex-basis: 100%;
            z-index: 5;

            @include respond-below(sm) {
                margin-top: 29px;
            }
        }
    }
}

// ===============================
//         SingleWOMCarousel
// ===============================

.single-wom-carousel-container {
    //@include test-border(red);

    width: 100%;
    display: flex;
    justify-content: center;

    .single-wom-carousel {
        width: 100%;
        //@include keep-ratio(558px, 1060px);

        @include respond-below(sm) {
            //@include keep-ratio(419px, 334px);
        }

        @include carousel-arrow($dark-color, $carousel-arrow-color);

        //@include respond-below(xs){
        //    .arrow {
        //        padding: 7px;
        //    }
        //}
        //
        @include respond-below(xxs) {
            .arrow {
                padding: 6.5px;
            }
        }

        .slider-frame {
            height: auto !important;
        }

        .slider-control-centerright {
            right: 1.5% !important;

            @include respond-below(xl) {
                right: 1.4% !important;
            }

            @include respond-below(lg) {
                right: 1.3% !important;
            }

            @include respond-below(md) {
                right: 1.2% !important;
            }

            @include respond-below(sm) {
                right: -3.3% !important;
            }

            @include respond-below(xs) {
                right: -3.7% !important;
            }

            @include respond-below(xxs) {
                right: -3.5% !important;
            }
        }

        .slider-control-centerleft {
            left: 1.5% !important;

            @include respond-below(xl) {
                left: 1.4% !important;
            }

            @include respond-below(lg) {
                left: 1.1% !important;
            }

            @include respond-below(md) {
                left: 1.2% !important;
            }

            @include respond-below(sm) {
                left: -3.3% !important;
            }

            @include respond-below(xs) {
                left: -3.7% !important;
            }

            @include respond-below(xxs) {
                left: -3.9% !important;
            }


        }

        .one-image-slide {
            @include background-image-contain(100%, 100%);
            //min-width: 350px;

            @include keep-ratio(558px, 1060px);

            @include respond-below(sm) {
                @include keep-ratio(419px, 334px);
            }
        }
    }
}


// ===============================
//   HeaderTwoParagraphsTwoImages
// ===============================
.header-two-paragraphs-two-images-section {
    position: relative;
    //box-sizing: border-box;
    //border: 1px dashed crimson;

    //* {
    //  box-sizing: border-box;
    //  border: 1px dashed crimson;
    //}

    .header-content {
        width: 100%;
        //min-height: 600px;
        margin-bottom: -110px;

        @include respond-below(sm) {
            margin-bottom: -62px;
        }

        .two-paragraphs-header-headline {
            flex-basis: 100%;
            margin: 25px 0 25px;

            .section-title {
                margin: 0;
            }

            @include respond-below(sm) {
                margin: 15px 0 15px;
            }
        }

        .header-paragraph-container {
            flex-basis: 82.26%;
            z-index: 5;
            margin-bottom: 150px;

            .header-paragraph {
                text-align: justify;
            }

            @include respond-below(sm) {
                flex-basis: 89.6%;
                margin-bottom: 100px;

                .header-paragraph {
                    flex-basis: 100%;
                }
            }
        }
    }

    .bottom-images {
        width: 83.2%;
        z-index: 5;
        //min-height: 500px;

        @include respond-below(sm) {
            width: 89.33%;
        }

        .bottom-image-container {
            position: relative;
            overflow: hidden;
            width: 50%;

            &.left {
                top: 0;
                left: 0;
            }

            &.right {
                top: 0;
                right: 0;
            }

            .bottom-image {
                width: 100%;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                transition: transform $zoom-transition-speed;

                @include keep-ratio(649px, 532px);

                @include respond-below(sm) {
                    @include keep-ratio(205px, 167px);
                }

                &:hover {
                    transform: $image-zoom-in-scale;
                }
            }
        }
    }
}


// =======================
//   Hotel Details
// =======================

.hotel-details-section {
    position: relative;

    //box-sizing: border-box;
    //border: 1px dashed crimson;
    //
    //* {
    //    box-sizing: border-box;
    //    border: 1px dashed crimson;
    //}

    .hotel-details-content {
        width: 100%;
        background-color: #f9e15d;
        //min-height: 600px;
        //margin-bottom: -281px;
        margin-bottom: -20%;

        @include respond-below(sm) {
            margin-bottom: 0;
        }

        .hotel-details-hide-background {
            @include hide-background($background-color);
        }

        .hotel-details-headline {
            flex-basis: 100%;
            margin: 0 0 60px;
            z-index: 2;

            .section-title {
                margin: 0;
            }

            @include respond-below(sm) {
                margin: 0 0 30px;
            }
        }

        .paragraph-container {
            flex-basis: 82.26%;
            z-index: 5;
            margin-bottom: 5%;

            .paragraph {
                text-align: justify;
                width: 100%;
                line-height: 1.67;
            }

            @include respond-below(sm) {
                flex-basis: 88.8%;

                .single-paragraph {
                    flex-basis: 100%;
                }
            }
        }

        .facility-icons {
            flex-basis: 70.78%;
            margin-bottom: 25%;

            @include respond-below(sm) {
                margin-bottom: 15%;
                flex-basis: 87.73%;
            }

            .facility-headline {
                flex-basis: 100%;
                text-align: center;
                margin-bottom: 25px;
            }

            .icons-container {
                .feature-icon-container {
                    margin: 0 10px 15px;
                    flex-basis: calc(7% - 22px * 2);
                    min-height: 85px;
                    max-height: 85px;
                    //min-width: 41px;
                    //max-width: 41px;

                    //&:first-child {
                    //    margin: 0 10px 15px 0;
                    //}
                    //
                    //&:last-child {
                    //    margin: 0 0 30px 10px;
                    //}

                    .icon-container {
                        margin-bottom: 17px;

                        .icon {
                            height: 40px;
                            width: 40px;
                            background-position: center;
                            background-size: contain;
                            background-repeat: no-repeat;
                        }

                        @include respond-below(sm) {
                            margin-bottom: 10px;
                        }
                    }

                    .label-container {
                        flex-basis: 100%;
                        min-height: 30px;

                        .label {
                            text-align: center;
                            font-size: 0.7rem;
                            line-height: 1;

                            @include respond-below(sm) {
                                font-size: 0.9rem;
                            }
                        }
                    }

                }
            }
        }

    }

    .bottom-images {
        width: 83.2%;
        z-index: 5;
        //min-height: 500px;

        @include respond-below(sm) {
            //width: 89.33%;
            display: none;
        }

        .image {
            position: relative;
            width: 50%;
            background-size: cover;
            background-position: center;

            @include keep-ratio(649px, 532px);

            &.left {
                top: 0;
                left: 0;
            }

            &.right {
                top: 0;
                right: 0;
            }

            @include respond-below(sm) {
                @include keep-ratio(205px, 167px);
                display: none;
            }

        }


    }
}

// =======================
//      HotelRooms
// =======================
.hotel-rooms-section {
    position: relative;

    @include respond-below(sm) {
        width: $section-wide-max-width;
        max-width: $screen-max-width;
    }

    //box-sizing: border-box;
    //border: 1px dashed crimson;
    //
    //* {
    //    box-sizing: border-box;
    //    border: 1px dashed crimson;
    //}

    .hotel-rooms-content {
        width: 100%;
        background-color: $theme-color-2;
        //min-height: 600px;
        margin-bottom: -10%;

        @include respond-below(md) {
            margin-bottom: 0;
        }

        .hotel-rooms-hide-background {
            @include hide-background($background-color);
        }

        .hotel-rooms-hide-background-mobile {
            @include respond-below(sm) {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 2;
                background-image: linear-gradient(
                                to right,
                                $background-color 6.4%,
                                transparent 6.4%,
                                transparent 93.6%,
                                $background-color 93.6%,
                                $background-color 100%
                );
            }

            @include respond-below(xxs) {
                background-image: linear-gradient(
                                to right,
                                $background-color 5.5%,
                                transparent 5.5%,
                                transparent 94.5%,
                                $background-color 94.5%,
                                $background-color 100%
                );
            }
        }

        .hotel-rooms-headline {
            flex-basis: 100%;
            margin: 0 0 60px;
            z-index: 2;

            .section-title {
                margin: 0;
            }

            @include respond-below(md) {
                margin: 0 0 30px;
            }
        }

        .paragraph-container {
            flex-basis: 82.26%;
            z-index: 5;
            margin-bottom: 5%;

            .paragraph {
                text-align: justify;
                width: 100%;
                line-height: 1.67;
            }

            @include respond-below(md) {
                flex-basis: 88.26%;

                .single-paragraph {
                    flex-basis: 100%;
                }
            }

            @include respond-below(sm) {
                flex-basis: 70%;
            }
        }

        .hotel-rooms-collection {
            margin-bottom: 15%;
            width: 100%;

            @include respond-below(md) {
                margin-bottom: 9%;
                justify-content: center !important;
            }

            .collection-headline {
                flex-basis: 100%;
                text-align: center;
                margin-bottom: 49px;
                line-height: 2.28;

                @include respond-below(md) {
                    margin-bottom: 24px;
                    flex-basis: 88.26%;

                    .section-subtitle {
                        text-align: left;
                    }
                }

                @include respond-below(sm) {
                    flex-basis: 70%;
                }
            }

            .rooms-container {
                flex-basis: 100%;

                @include respond-below(md) {
                    flex-wrap: wrap;
                }

                .room-container {
                    flex-basis: 33%;
                    margin: 0 15px;

                    &:first-child {
                        margin: 0 15px 0 0;
                    }

                    &:last-child {
                        margin: 0 0 0 15px;
                    }

                    @include respond-below(md) {
                        flex-basis: 100%;
                        margin: 0 !important;
                    }

                    .image-container {
                        flex-basis: 100%;
                        position: relative;
                        margin-bottom: 15.45%;
                        z-index: 2;

                        @include keep-ratio(214px, 406px);

                        @include respond-below(md) {
                            @include keep-ratio(253px, 479px);
                        }

                        &:hover + .room-details {
                            font-weight: bold;

                            .headline {
                                font-size: 1.35rem
                            }

                            .details-list {
                                font-size: 1.05rem;
                            }
                        }

                        &:hover .image-number {
                            font-weight: bold;
                        }

                        .image-number {
                            position: absolute;
                            top: 31px;
                            left: 31px;
                            z-index: 2;
                            font-size: 2rem;
                            text-shadow: 0 0 1px rgba(255, 255, 255, 0.69);
                            font-family: 'Headlines-Font', sans-serif;
                        }

                        &.desktop {
                            @include respond-below(md) {
                                display: none;
                            }
                        }

                        &.mobile {
                            display: none;
                            position: relative;
                            margin-bottom: 5%;

                            @include respond-below(md) {
                                display: block;
                            }
                        }

                        .image {
                            position: absolute;
                            top: 0;
                            left: 0;
                            @include background-image-cover(100%, 100%);
                        }
                    }


                    .room-details {
                        //flex-basis: 73.15%;
                        padding: 0 15%;

                        @include respond-below(md) {
                            margin-bottom: 32px;
                            //flex-basis: 88.26%;
                            padding: 0 15%;

                        }

                        @include respond-below(sm) {
                            flex-basis: 70%;
                        }

                        .headline {
                            flex-basis: 100%;
                            font-size: 1.333rem;
                            margin: 0 0 45px;
                            text-align: left;
                            font-family: 'Headlines-Font', sans-serif;

                            @include respond-below(md) {
                                margin: 0 0 15px;
                            }
                        }

                        .details-list {
                            text-align: left;
                            line-height: 2.67;

                            * {
                                margin: 0;
                                padding: 0;
                                //list-style-position: inside;
                            }

                            @include respond-below(md) {
                                line-height: 1.5;
                                //font-size: 0.85rem;
                            }
                        }

                    }
                }
            }
        }
    }

    .amenities-icons {
        width: 77.18%;
        background-color: $theme-color-4;

        @include respond-below(md) {
            width: 100%;
        }

        .amenities-headline {
            flex-basis: 100%;
            text-align: center;
            margin: 5% 0 6%;

            @include respond-below(md) {
                margin: 9% 0 6%;
            }
        }

        .amenities-icons-container {
            flex-basis: 91.9%;
            margin-bottom: 7%;

            @include respond-below(md) {
                margin-bottom: 5%;
            }

            .amenities-icon-container {
                margin: 0 10px 15px;
                flex-basis: calc(7% - 22px * 2);
                min-height: 85px;
                max-height: 85px;
                //min-width: 85px;
                //max-width: 85px;

                //&:first-child {
                //    margin: 0 10px 15px 0;
                //}
                //
                //&:last-child {
                //    margin: 0 0 30px 10px;
                //}

                .icon-container {
                    margin-bottom: 17px;

                    .icon {
                        height: 40px;
                        width: 40px;
                        background-position: center;
                        background-size: contain;
                        background-repeat: no-repeat;
                    }

                    @include respond-below(md) {
                        margin-bottom: 10px;
                    }
                }

                .label-container {
                    flex-basis: 100%;
                    min-height: 30px;

                    .label {
                        text-align: center;
                        font-size: 0.7rem;
                        line-height: 1;

                        @include respond-below(md) {
                            //font-size: 0.9rem;
                        }
                    }
                }

            }
        }
    }
}


// =======================
//   BackgroundWithButton
// =======================

.background-with-button-section {
    position: relative;
    overflow: hidden;

    @include keep-ratio(627px, 1920px);

    .background-image {
        position: absolute;
        top: 0;
        left: 0;
        transition: transform $zoom-transition-speed;

        @include background-image-cover(100%, 100%);

        &:hover {
            transform: $image-zoom-in-scale;
        }

    }

    .book-btn {
        position: absolute;
        bottom: 15.94%;
        z-index: 1;

        &:hover {
            //background-color: rgba();
        }
    }

    @include respond-below(sm) {
        &::before {
            content: "";
            width: 1px;
            margin-left: -1px;
            float: left;
            height: 0;
            padding-top: 271px / 375px * 100%;
        }

        &::after { /* to clear float */
            content: "";
            display: table;
            clear: both;
        }
    }
}

// =======================
//      ContactHotels
// =======================
.contact-hotels-section {

}


// =======================
//    MapWithLabelWide
// =======================

.map-with-label-wide {
    position: relative;
    width: 100%;
    min-height: 430px;

    @include keep-ratio(618px, 1919px);

    @include respond-below(sm) {
        @include keep-ratio(291px, 375px);
    }

    .map-container {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        top: 0;
        left: 0;

        .map {
            height: 100%;
        }

        @include respond-below(sm) {
            height: 93.12%;
        }
    }


    .label-container {
        position: absolute;
        right: 16.62%;
        bottom: 22%;
        overflow: hidden;
        height: 56.14%;
        width: 30%;
        min-width: 392px;
        min-height: 79px;
        box-shadow: 0 8.5px 13.5px 0 rgba(0, 0, 0, 0.09);

        @include respond-below(sm) {
            height: 54.29%;
            width: 69.6%;
            bottom: 0;
            right: 5.333%;

            min-width: 261px;
            min-height: 156px;
        }

        .label {
            height: 100%;
            width: 100%;
            z-index: 1;
            align-self: center;
            background: $text-light;
            overflow: hidden;
            cursor: pointer;
            padding: 1rem;
            transition: transform ease-in $zoom-transition-speed,
            opacity ease-in $zoom-transition-speed;

            .logo {
                width: 206px;
                height: 45px;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            }

            .address {
                line-height: 1.44;

                @include respond-below(sm) {
                    line-height: 1.17;
                }

                * {
                    margin: 0;
                }
            }

            .phone-mail {
                line-height: 1.44;

                @include respond-below(sm) {
                    line-height: 1.17;
                }

                * {
                    margin: 0;
                }
            }
        }
    }
}


// =======================
//    SingleContactHotel
// =======================

.single-contact-hotel {
    position: relative;
    width: 100%;
    margin-bottom: 100px;
    min-height: 430px;

    @include keep-ratio(618px, 1919px);

    .image-container {
        position: absolute;
        width: 50%;
        height: 100%;
        overflow: hidden;
        top: 0;
        left: 0;

        .image {
            width: 100%;
            height: 100%;
            transition: transform ease-in $zoom-transition-speed,
            opacity ease-in $zoom-transition-speed;
            background-size: cover;
            background-position: center;

            &:hover {
                transform: $image-zoom-in-scale;
                opacity: $image-opacity-hover;
            }
        }
    }

    .map-container {
        position: absolute;
        width: 50%;
        height: 100%;
        overflow: hidden;
        bottom: 0;
        right: 0;

        .map {
            height: 100%;
        }
    }

    .label-container {
        position: absolute;
        overflow: hidden;
        height: 72.5%;
        width: 30%;
        align-self: center;
        min-width: 392px;
        min-height: 79px;
        box-shadow: 0 8.5px 13.5px 0 rgba(0, 0, 0, 0.09);


        .label {
            height: 100%;
            width: 100%;
            z-index: 1;
            align-self: center;
            background: $text-light;
            overflow: hidden;
            //cursor: pointer;
            padding: 1rem;
            transition: transform ease-in $zoom-transition-speed,
            opacity ease-in $zoom-transition-speed;

            .address {
                * {
                    margin: 0;
                }
            }

            .phone-mail {
                * {
                    margin: 0;
                }
            }
        }

        @include respond-below(sm) {
            width: 69.23%;
            height: 30.44%;
            margin-left: 10%;
            min-height: 160px;
            min-width: 260px;

            .label {
                .the-wom-btn {
                    display: none;
                }

                .label-title {
                    //font-size: 1.8rem;
                    //margin-top: 25px;
                }

                .address {
                    * {
                        font-size: 1.4rem;
                    }
                }

                .phone-mail {
                    * {
                        font-size: 1.4rem;
                    }
                }
            }

        }

        @include respond-below(xs) {
            .label {
                .label-title {
                    font-size: 2rem;
                    margin-bottom: 5px;
                }

                .address {
                    * {
                        font-size: 1.1rem;
                    }
                }

                .phone-mail {
                    * {
                        font-size: 1.1rem;
                    }
                }
            }
        }

        @include respond-below(xxs) {

            .label-title {
                font-size: 1.8rem;

                margin-bottom: 5px;
            }

            .address {
                * {
                    font-size: 1.1rem;
                }
            }

            .phone-mail {
                * {
                    font-size: 1.1rem;
                }
            }
        }
    }

    @include respond-below(sm) {
        flex-wrap: wrap;
        margin-bottom: 50px;
        min-height: 519px;
        padding-right: 20px;

        @include keep-ratio(519px, 377px);

        .image-container {
            height: 47.7%;
            width: 100%;
        }

        .map-container {
            height: 53.3%;
            width: 100%;
        }
    }
}

// =======================
//    HeaderOneImage
// =======================

.header-one-image-section {
    .header-one-image-background {
        width: 100%;
        margin-bottom: -57%;
        @include keep-ratio(759px, 1280px);

        @include respond-below(sm) {
            @include keep-ratio(358px, 375px);
            margin-bottom: -90%;
        }
    }

    .header-one-image-content {
        width: 100%;

        .headline {
            margin-bottom: 4%;
            flex-basis: 100%;

            .section-title {
                margin-bottom: 30px;
            }
        }

        .image {
            width: 83%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;

            @include keep-ratio(651px, 1062px);

            @include respond-below(sm) {
                @include keep-ratio(419px, 333px);
                width: 90%;
            }
        }
    }
}

// =======================
//      ContactUsModal
// =======================

.contact-us-modal {
    max-width: 80% !important;
    margin: calc(#{$navbar-height} * 2) 10% 0;

    @include respond-below(xl) {
        margin: calc(#{$navbar-height} * 2) 125px 0;
    }

    @include respond-below(sm) {
        margin: 10px 50px;
    }

    @include respond-below(xs) {
        max-width: 100% !important;
        margin: 10px 10px;
    }

    .contact-us-body {
        width: 100%;
        margin: 45px 0;

        .contact-us-headline {
            flex-basis: 100%;
            text-align: center;

            @include respond-below(lg) {
                .section-title {
                    font-size: 3.5rem;
                }
            }

            @include respond-below(md) {
                .section-title {
                    font-size: 2.5rem;
                }
            }

            @include respond-below(sm) {
                margin-bottom: 20px;

            }
        }

        .contact-us-form {
            width: 83%;

            @include respond-below(lg) {
                width: 90%;
            }

            @include respond-below(md) {
                width: 80%;
            }

            @include respond-below(sm) {
                width: 90%;
            }

            .contact-us-form-row {
                flex-basis: 100%;
                margin-bottom: 50px;

                &:last-child {
                    margin-bottom: 0;
                }

                .contact-us-input {
                    border: none;
                    flex-basis: 44.5%;
                    border-bottom: 1px solid $theme-color-4;
                    background: transparent;
                    padding-bottom: 22px;

                    @include respond-below(sm) {
                        flex-basis: 100%;
                        padding-bottom: 10px;

                        &:first-child {
                            margin-bottom: 40px;
                        }
                    }

                    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                        color: $theme-color-4;
                    }

                    &::-moz-placeholder { /* Firefox 19+ */
                        color: $theme-color-4;
                    }

                    &:-ms-input-placeholder { /* IE 10+ */
                        color: $theme-color-4;
                    }

                    &:-moz-placeholder { /* Firefox 18- */
                        color: $theme-color-4;
                    }
                }

                .contact-us-input:focus {
                    outline-width: 0;
                }

                .contact-us-text-area {
                    width: 100%;
                    min-height: 177px;
                    padding: 20px;

                    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                        color: $theme-color-4;
                    }

                    &::-moz-placeholder { /* Firefox 19+ */
                        color: $theme-color-4;
                    }

                    &:-ms-input-placeholder { /* IE 10+ */
                        color: $theme-color-4;
                    }

                    &:-moz-placeholder { /* Firefox 18- */
                        color: $theme-color-4;
                    }
                }

                .contact-us-text-area:focus {
                    outline-width: 0;
                }
            }
        }
    }

    .contact-us-footer {
        flex-basis: 100%;

    }

}

// =======================
//      HeaderBackgroundImageWithHeadlines
// =======================

.header-background-image-with-headlines {
    min-height: 324px;
    min-width: 640px;
    background-size: cover;
    background-position: center;
    justify-content: center;
    position: relative;

    @include keep-ratio(324px, 640px);

    .headline-and-sub {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: $text-light;
    }

    @include respond-below(sm) {
        min-height: 233px;
        min-width: 188px;
        @include keep-ratio(233px, 188px);

        .headline-and-sub {
            .section-title {
                font-size: 4.25rem;
            }

            .section-subtitle {
                font-size: 1.85rem;
            }
        }
    }
}

// =======================
//   TwoImagesHorizontal
// =======================
.header-images {
    width: 100%;
    height: 0;
    padding-top: 325px / 266.5px * 100%;
    position: relative;

    .img {
        position: absolute;
        background-size: cover;
        background-position: center;
        height: 100%;
        width: 50.1%;
    }

    .img-left {
        top: 0;
        left: 0;
    }

    .img-right {
        top: 0;
        right: 0;
    }
}

// ==========================
//      PageNavigationPanel
// ==========================

.page-navigation-panel {
    margin: $page-navigation-panel-margin;
    max-width: $main-max-width;


    @include respond-below(xl) {
        margin: $page-navigation-panel-margin-xl;
    }

    @include respond-below(lg) {
        margin: $page-navigation-panel-margin-lg;
    }

    @include respond-below(md) {
        margin: $page-navigation-panel-margin-md;
    }

    @include respond-below(sm) {
        margin: $page-navigation-panel-margin-sm;
        width: 100% !important;
    }

    @include respond-below(xs) {
        margin: $page-navigation-panel-margin-xs;
    }

    .page-navigation-panel-content {
        width: 82.81%;

        @include respond-below(sm) {
            width: 89.3%;
        }

        .nav-item {
            //color: $theme-color-6 !important;
            font-size: 1.5rem;
            cursor: pointer;
            transition: font-weight $nav-link-transition-speed;
            font-family: 'Headlines-Font', sans-serif;
            line-height: 1.33;

            @include respond-below(lg) {
                // font-size: 1.8rem;
            }

            @include respond-below(sm) {
                line-height: 1.6;
                // font-size: 1.75rem;
            }

            @include respond-below(xs) {
                // font-size: 1.5rem;
            }

            @include respond-below(xxs) {
                // font-size: 1.3rem;
            }

            a {
                text-decoration: none;
                color: $dark-color;
            }

            &:hover {
                font-weight: bold;
            }
        }
    }
}

// =================================
//      GalleryPageNavigationPanel
// =================================

.gallery-page-navigation-panel {
    margin: $page-navigation-panel-margin;
    max-width: $main-max-width;


    @include respond-below(xl) {
        margin: $page-navigation-panel-margin-xl;
    }

    @include respond-below(lg) {
        margin: $page-navigation-panel-margin-lg;
    }

    @include respond-below(md) {
        margin: $page-navigation-panel-margin-md;
    }

    @include respond-below(sm) {
        margin: $page-navigation-panel-margin-sm;
        width: 100% !important;
    }

    @include respond-below(xs) {
        margin: $page-navigation-panel-margin-xs;
    }

    .gallery-page-navigation-panel-content {
        width: 82.81%;

        @include respond-below(sm) {
            width: 89.3%;
        }

        .nav-item {
            //color: $theme-color-6 !important;
            font-size: 2rem;
            cursor: pointer;
            transition: font-weight $nav-link-transition-speed;
            font-family: 'Headlines-Font', sans-serif;
            line-height: 1.33;

            @include respond-below(lg) {
                font-size: 1.8rem;
            }

            @include respond-below(sm) {
                line-height: 1.6;
                font-size: 1.75rem;
            }

            @include respond-below(xs) {
                font-size: 1.5rem;
            }

            @include respond-below(xxs) {
                font-size: 1.3rem;
            }

            a {
                text-decoration: none;
                color: $dark-color;
            }

            &:hover {
                font-weight: bold;
            }
        }
    }
}

// ===========================
//      CollectionItemHeader
// ===========================

.collection-item-header {
    .collection-item-header-content {
        position: relative;
        width: 100%;

        .headline {
            width: 90%;
            margin: 75px auto 30px;
        }

        .back-btn {
            position: absolute;
            top: 35px;
            left: 50px;
            z-index: 5;
            cursor: pointer;
            color: $light-color;
            font-weight: 500;
            transition: color $opacity-transition-speed;

            @include respond-below(sm) {
                top: 20px;
                left: 21px;
            }

            &:hover {
                .arrow-left {
                    color: $dark-color;
                }

                .back-btn-label {
                    color: $dark-color;
                }
            }

            .back-btn-label {
                margin-left: 30px;
                transition: color $opacity-transition-speed;

                @include respond-below(sm) {
                    margin-left: 20px;
                }
            }

            .arrow-left {
                position: relative;
                display: inline-block;
                vertical-align: middle;
                color: $light-color;
                box-sizing: border-box;
                transition: color $opacity-transition-speed;

                width: 20px;
                height: 20px;
                border-width: 1px 1px 0 0;
                border-style: solid;
                margin: 0;
                transform: rotate(-135deg);

                &:after, &:before {
                    content: "";
                    box-sizing: border-box;
                }

                &:before {
                    right: 0;
                    top: -1px;
                    position: absolute;
                    height: 1px;
                    box-shadow: inset 0 0 0 32px;
                    transform: rotate(-45deg);
                    width: 38px;
                    transform-origin: right top;

                    @include respond-below(sm) {
                        width: 30px;
                    }
                }
            }


            //&:hover {
            //    color: $dark-color;
            //}
        }

        .intro-text-container {
            width: 40%;

            margin-bottom: 56px;

            @include respond-below(sm) {
                width: 78.34%;
                margin-bottom: 27px;
            }

            .intro-text {
                flex-basis: 100%;
                transition: width 0.2s, font-size 0.2s;
                text-align: center;
                margin-bottom: 10px;
                align-self: center;
                line-height: 1.67;

                @include respond-below(sm) {
                    font-size: 1.4rem;
                }
            }

            .intro-location {
                .intro-location-icon {
                    @include background-image-contain(25px, 16px);
                }

                .intro-location-label {
                    font-size: 0.9rem;

                    @include respond-below(sm) {
                        font-size: 1.3rem;
                    }
                }
            }
        }

        .intro-image-container {
            width: 82.81%;

            @include respond-below(sm) {
                width: 100%;
            }

            .intro-image {
                width: 100%;
                background-size: cover;
                background-position: center;

                @include keep-ratio(651px, 1064px);

                @include respond-below(sm) {
                    @include keep-ratio(229px, 375px);
                    margin-bottom: 40px;
                }
            }
        }
    }
}


// ===========================
//      HeaderCenterText
// ===========================

.header-center-text {
    .header-center-text-content {
        position: relative;
        width: 100%;

        .headline {
            margin: 30px 0 30px;

            @include respond-below(sm) {
                margin: 15px 0 15px;
            }
        }

        .intro-text {
            width: 54.5%;
            transition: width 0.2s, font-size 0.2s;
            text-align: center;
            margin-bottom: 80px;
            align-self: center;
            line-height: 1.67;

            @include respond-below(sm) {
                width: 89%;
                margin-bottom: 50px;
                text-align: justify;
            }
        }

        .intro-image-container {
            width: 82.81%;

            @include respond-below(sm) {
                width: 89.3%;
            }

            .intro-image {
                height: 100%;
                width: 100%;
                background-size: cover;
                background-position: center;

                @include keep-ratio(558px, 1060px);

                @include respond-below(sm) {
                    @include keep-ratio(419px, 334px);
                }
            }
        }
    }
}

// =======================
//      CollectionDetails
// =======================

.collection-details {
    padding: 0;
    width: 82.81%;

    @include respond-below(xl) {
        padding: $collection-item-content-padding;
    }

    @include respond-below(sm) {
        padding: 0;
    }

    .collection-details-headline {
        text-align: left;
        width: 100%;
    }

    .paragraph-and-details {
        margin-bottom: 50px;
        width: 100%;

        @include respond-below(sm) {
            margin-bottom: 28px;
        }

        .paragraph {
            flex-basis: 50%;
            text-align: justify;

            @include respond-below(sm) {
                flex-basis: 100%;
                margin-bottom: 28px;
            }
        }

        .details-list {
            flex-basis: 36%;
            background-color: $theme-color-4;
            padding: 20px;

            @include respond-below(sm) {
                flex-basis: 100%;
                padding: 15px;

            }

            .details-list-item {
                margin-bottom: 5px;
                flex-basis: 100%;

                &:last-child {
                    margin-bottom: 0;
                }

                .title {
                    margin-right: 15px;
                    font-size: 1.5rem;
                    font-weight: bold;

                    @include respond-below(sm) {
                        font-size: 1.3rem;
                        margin-right: 10px;
                    }
                }

                .description {
                    p {
                        margin: 0;
                        text-align: left;
                    }
                }
            }
        }
    }

    .paragraph-wide {
        text-align: justify;
    }
}

// =======================
//     SingleImageCenter
// =======================

.single-image-center {
    .single-image-center-container {
        position: relative;
        width: 100%;

        @include keep-ratio(650px, 1279px);

        @include respond-below(sm) {
            @include keep-ratio(191px, 375px);
            width: 100vw;
        }

        .single-image {
            position: absolute;
            top: 0;
            left: 0;
            @include background-image-cover(100%, 100%)
        }
    }
}

// =======================
//      SingleImageWide
// =======================

.single-image-wide {
    .single-image-wide-container {
        position: relative;
        width: 100%;

        @include keep-ratio(626px, 1920px);

        @include respond-below(sm) {
            @include keep-ratio(239px, 375px);
        }

        .single-image {
            position: absolute;
            top: 0;
            left: 0;
            @include background-image-cover(100%, 100%)
        }
    }
}

// =======================
//      HeadlineBig
// =======================

.article-headline-big {
    margin-bottom: 25px;
    padding: 0;

    @include respond-below(xl) {
        padding: $collection-item-content-padding;
    }

    @include respond-below(sm) {
        margin-bottom: 15px;
        padding: 0;
    }

    .section-title {
        width: 100%;
        text-align: left;
        margin: 0;
    }
}

// =======================
//      HeadlineSmall
// =======================

.article-headline-small {
    margin-bottom: 25px;
    padding: 0;

    @include respond-below(xl) {
        padding: $collection-item-content-padding;
    }

    @include respond-below(sm) {
        margin-bottom: 15px;
        padding: 0;
    }

    .section-subtitle {
        width: 100%;
        text-align: left;
        margin: 0;
    }
}


// =======================
//      ArticleImage
// =======================

.article-image-section {
    .article-image-container {
        position: relative;
        width: 100%;

        @include keep-ratio(650px, 1279px);

        @include respond-below(sm) {
            @include keep-ratio(191px, 375px);
        }

        .article-image {
            position: absolute;
            top: 0;
            left: 0;
            @include background-image-cover(100%, 100%)
        }
    }
}

// =======================
//      ArticleParagraph
// =======================

.article-paragraph {
    margin-bottom: 40px;
    padding: 0;

    @include respond-below(xl) {
        padding: $collection-item-content-padding;
    }

    @include respond-below(sm) {
        margin-bottom: 30px;
        padding: 0;
    }

    .article-paragraph-content {
        width: 100%;
        text-align: justify;

        hr {
            border: 0;
            height: 1px;
            background-color: $theme-color-6;
            background-image: linear-gradient(to right, $theme-color-4, $theme-color-6, $theme-color-4);
        }
    }
}

// =======================
//          FAQ
// =======================

.the-wom-faq {
    padding: 0 16%;

    @include respond-below(sm) {
        padding: 0 5%;
    }

    .single-faq {
        margin-bottom: 40px;

        @include respond-below(sm) {
            margin-bottom: 20px;
        }

        .faq-question-container {
            .question {
                margin-right: 20px;
            }

            .faq-arrow {
                border: solid $theme-color-6;
                border-width: 0 3px 3px 0;
                display: inline-block;
                padding: 3px;
                transform: rotate(45deg);
                transition: transform 300ms;
            }

            .faq-label {
                cursor: pointer;
                text-align: left;

                @include respond-below(sm) {
                    justify-content: space-between;
                    width: 100%;
                }
            }

            .faq-arrow-input {
                display: none;

                &:checked ~ .faq-answer {
                    height: auto;
                }

                &:checked ~ .faq-label .faq-arrow {
                    transform: rotate(-135deg);
                }
            }
        }

        .faq-answer {
            overflow: hidden;
            height: 0;
            flex-basis: 100%;
            text-align: justify;
        }
    }

}

// =======================
//      SimplexContainer
// =======================

.simplex-container {

    &.rtl {
        direction: rtl;
        text-align: right;

        input {
            text-align: right;
        }

        .engine-type-main {
            .resort-holder {
                //border: none !important;
                border-right: none !important;
            }

            .bottom-engine-holder {
                .promo-holder {
                    border-right: 1px solid $simplex-input-border-color;
                }
            }
        }
    }


}

.header-home__simplex-container {
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    display: flex;
    justify-content: center;
    margin-top: -30px;
    margin-bottom: 50px;
    position: relative;

    .engine-type-main {
        width: 95% !important;
        padding: 0;
    }

    @include respond-below(md) {
        position: relative;
        margin-top: 32px;
    }

    @include respond-below(xs) {
        bottom: 4%;
    }

    * {
        // margin: 0 !important;
        font-family: 'Text-Font', sans-serif !important;
        text-align: center;
        //box-shadow: 0px 17px 27px 0 rgba(0, 0, 0, 0.06);
        cursor: pointer;
    }

    // Rooms Select middle row
    .engine-type-main .rooms-and-paxs-holder #extraRoomPaxPanel .panel-holder .table-structure-holder .panel-paxs .room-pax-row .pax-holder {
        width: 30% !important;
    }

    .engine-type-main div {
        font-family: 'Text-Font', sans-serif !important;
    }

    .engine-type-main .rooms-and-paxs-holder #extraRoomPaxPanel .panel-holder .table-structure-holder .panel-paxs .room-pax-row .pax-holder select {
        border: 1px solid $theme-color-6;
        outline-color: $theme-color-6;
        padding-left: 10px !important;
    }

    .engine-type-main .datepickers .datepicker-holder .datepicker-custom-format .date-title {
        padding-right: 4px;
        @include respond-below(md) {
            padding-right: 0;
            width: 100%;
        }
    }

    .engine-type-main {
        width: 100%;
        max-width: $main-max-width;
        display: flex;
        height: 750px !important;
        box-shadow: 0 17px 27px 0 rgba(0, 0, 0, 0.06);
        padding: 0;

        @include respond-below(xl) {
            padding: 0 15px;
        }

        @include respond-below(md) {
            flex-wrap: wrap;
            height: auto;
            max-width: 60%;
            box-shadow: none;
            padding: 0;
        }

        @include respond-below(sm) {
            max-width: $section-center-max-width-sm;
        }

        .resort-holder {
            //flex-basis: 22%;
            flex-grow: 1;
            height: 100%;
            border-right: 1px solid $simplex-input-border-color;
            width: initial;

            @include respond-below(md) {
                flex-basis: 100%;
                height: $simplex-header-input-height-xs;
                margin-bottom: 30px !important;
                border: none;
            }

            @include respond-below(xs) {
                height: $simplex-header-input-height-xs;
                margin-bottom: 15px;
            }

            .engine-header {

            }

            .select-hotel-holder {
                height: 100%;

                @include respond-below(md) {
                    border: 1px solid $simplex-input-border-color;
                    height: $simplex-header-input-height-xs;
                }

                .hotel-header {

                }

                select {
                    border: none;
                    background: $theme-color-5;
                    height: 100%;
                    align-items: center;
                    font-size: 1rem;
                    text-align-last: center;
                    text-align: center;
                    outline: none !important;
                    white-space: pre-line;
                    border-radius: 0 !important;

                    &:focus {
                        outline: none !important;
                    }

                    @include respond-below(md) {
                        padding: $simplex-input-padding-xs;
                        text-align-last: left;
                        text-align: left;
                        color: $simplex-input-mobile-text-color !important;
                    }

                    @include respond-below(xs) {
                        font-size: 1.2rem;
                    }

                    option {
                    }

                    optgroup {
                        border-bottom: 1px solid $simplex-separator;
                        //font-size: 10rem !important;

                        .allSelect {
                            font-size: 1rem;
                            font-family: 'Text-Font', sans-serif;

                            @include respond-below(xs) {
                                font-size: 1.2rem;
                            }
                        }

                        &:last-child {
                            border: none;
                        }
                    }

                    optgroup[label] {
                        font-size: 1rem;
                        background: inherit;

                        @include respond-below(xs) {
                            font-size: 1.2rem;
                        }
                    }

                }
            }
        }

        .datepickers {
            //flex-basis: 44%;
            flex-grow: 2;
            width: initial;
            display: block !important;

            @include respond-below(md) {
                flex-basis: 100%;
                display: flex !important;
                flex-flow: row wrap;
            }

            .datepicker-holder {
                background: $theme-color-5;
                border-right: 1px solid $simplex-input-border-color;
                width: 50%;
                height: 100%;

                @include respond-below(md) {
                    flex-basis: 100%;
                    margin-bottom: 30px !important;
                    height: $simplex-header-input-height-xs;
                    border: 1px solid $simplex-input-border-color;
                }

                .datepicker {
                    height: 100%;

                    * {
                        font-size: 1rem;

                        @include respond-below(xs) {
                            font-size: 1.2rem;
                        }
                    }

                    .datepicker-input {
                        outline: none !important;
                    }

                    .datepicker-custom-format {
                        background: none;
                        height: 100%;
                        display: flex;
                        flex-flow: row wrap;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        padding-left: 0;

                        @include respond-below(md) {
                            justify-content: flex-start;
                            padding: $simplex-input-padding-xs;
                            color: $simplex-input-mobile-text-color !important;
                        }

                        .date-title {
                            @include respond-below(md) {
                                width: unset !important;
                                margin-right: 5px !important;
                            }
                        }

                        .date-elemnt-0 {
                            margin-right: 3px !important;
                        }

                        .date-elemnt-0, .date-elemnt-1 {
                            @include respond-below(md) {
                                //color: $dark-color;
                            }
                        }
                    }
                }


            }

            #datePickersLoader {

            }
        }

        .rooms-and-paxs-holder {
            //flex-basis: 22%;
            flex-grow: 1;
            width: initial;
            border: none;

            @include respond-below(md) {
                flex-basis: 100%;
                border: 1px solid $simplex-input-border-color;
                margin: $simplex-input-margin-xs !important;
            }

            #extraRoomPaxPanel {
                height: 100%;

                @include respond-below(md) {
                    height: $simplex-header-input-height-xs;
                }

                .panel-title {
                    height: 100%;
                    border: none !important;
                    border-right: 1px solid $simplex-input-border-color !important;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: none;

                    span {
                        font-size: 1rem;
                        padding: 0 !important;
                        width: unset;
                        font-family: 'Text-Font', sans-serif !important;

                        @include respond-below(xs) {
                            font-size: 1.2rem;
                        }
                    }

                    @include respond-below(md) {
                        justify-content: flex-start;
                        padding: $simplex-input-padding-xs;

                        span {
                            color: $simplex-input-mobile-text-color;
                        }
                    }
                }

                .panel-holder {
                    background: $theme-color-5;
                    top: $simplex-header-height + 10px;
                    box-shadow: 0 17px 27px 0 rgba(0, 0, 0, 0.16);
                    right: 15px;

                    @include respond-below(md) {
                        right: unset;
                    }

                    &::before {
                        border-color: transparent transparent $theme-color-5;
                    }

                    .close-panel {
                        width: 100%;
                        text-align: right !important;
                        padding: 10px !important;

                        &:hover {
                            font-weight: bold;
                        }
                    }

                    .table-structure-holder {
                        padding: 10px 30px 30px;

                        @include respond-below(lg) {
                            padding: 5px 10px 10px !important;
                        }

                        .paxs-header {
                            margin: 0 0 10px !important;
                            width: 100%;
                            display: flex;
                            justify-content: flex-start;

                            * {
                                font-size: 1rem;

                                @include respond-below(xs) {
                                    font-size: 1.2rem;
                                }
                            }

                            .paxs-holder {
                            }
                        }

                        .panel-paxs {
                            .room {
                            }

                            .room-pax-row {
                                margin: 0 0 20px !important;
                                display: flex;
                                justify-content: flex-start;

                                .room-title {
                                    display: none;
                                }

                                .paxs-holder {
                                    width: 30% !important;

                                    select {
                                        outline-color: $theme-color-6;
                                        border: 1px solid $theme-color-6 !important;
                                    }
                                }

                                .remove-room-panel {
                                    position: relative;
                                    background-color: $theme-color-6;
                                }
                            }
                        }

                        .bottom-panel-holder {
                            width: 100%;
                            display: flex;
                            justify-content: center;

                            #add-rooms-panel {
                            }

                            .close-panel-label {
                                font-size: 1rem;
                                background-color: $dark-btn-background;
                                color: $text-light !important;
                                border: none;
                                transition: background-color 200ms;

                                &:hover {
                                    background-color: $dark-btn-hover-background;
                                    color: $text-light;
                                }

                            }
                        }
                    }
                }
            }
        }

        .bottom-engine-holder {
            height: 100%;
            //flex-basis: 12%;
            flex-grow: 1;
            width: initial;
            display: flex;

            @include respond-below(md) {
                height: 66px !important;
                flex-basis: 100%;
                flex-wrap: wrap;
            }

            .promo-holder {
                @include respond-below(md) {
                    height: 35px !important;
                    flex-basis: 100%;
                    border: 1px solid $simplex-input-border-color;
                    margin-bottom: 20px !important;
                }
                height: 100%;
                flex-grow: 1;
                width: initial;

                .promo-input {
                    input {
                        font-size: 1rem;
                        width: 100%;
                        height: 100%;
                        @include respond-below(md) {
                            text-align: left;
                        }
                    }

                }
            }

            .engine-button-row {
                height: 100%;
                width: initial;
                flex-basis: 50%;
                display: flex;
                justify-content: center;

                @include respond-below(md) {
                    flex-basis: 100%;
                    padding-bottom: 50px;
                }

                .search-button {
                    flex-basis: 207px;
                    width: 100%;
                    height: 66px !important;
                    font-size: 18px;
                    line-height: 4;

                    background-color: $dark-btn-background;
                    color: $text-light !important;
                    border: none;
                    transition: background-color 200ms;

                    &:hover {
                        //opacity: 0.7;
                        background-color: $dark-btn-hover-background !important;
                        color: $text-light !important;
                    }
                }
            }
        }
    }
}


.ui-datepicker {

    width: 430px;

    @include respond-below(lg) {
        width: 50% !important;
    }

    @include respond-below(md) {
        width: 85% !important;
        left: 7% !important;
    }

    @include respond-below(xs) {
        width: 100% !important;
        left: 0 !important;
    }

    &::before {
        border-color: transparent transparent $theme-color-4 transparent !important;
    }

    * {
        font-family: 'Text-Font', sans-serif !important;
    }

    .ui-datepicker-header {
        background: $theme-color-4;
        color: $dark-color;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: 'Text-Font', sans-serif;
        width: 100%;
        height: 57px;
        padding: 0;

        &::after {
            content: none;
        }

        .ui-datepicker-prev, .ui-datepicker-next {
            position: relative;
            top: inherit;
            width: 0;
            height: 0;

            background: none;
            border: solid $theme-color-1;
            border-width: 0 1px 1px 0;
            display: inline-block;
            padding: 7px;
            cursor: pointer;
            transition: border-color 200ms;
            margin: 0 !important;

            &:hover {
                border-color: $theme-color-6;
            }

            .ui-icon {
                display: none;
            }

            .ui-icon-circle-triangle {

            }
        }

        .ui-datepicker-next {
            order: 3;
            //.ui-icon {
            transform: rotate(-45deg);
            //}
        }

        .ui-datepicker-prev {
            order: 1;
            //.ui-icon {
            transform: rotate(135deg);
            //}
        }

        //.arrow {
        //    border: solid $color;
        //    border-width: 0 1px 1px 0;
        //    //height: 26px;
        //    //width: 21px;
        //    display: inline-block;
        //    padding: 13px;
        //    cursor: pointer;
        //    transition: border-color 200ms;
        //
        //    @include respond-below(sm){
        //        padding: 9px;
        //    }
        //
        //
        //
        //    &.right {
        //    }
        //
        //    &.left {
        //
        //    }
        //}


        .ui-datepicker-title {
            order: 2;

            * {
                color: $theme-color-6;
                font-size: 1rem;
            }
        }
    }

    .ui-datepicker-calendar {
        box-shadow: 0 17px 27px 0 rgba(0, 0, 0, 0.16);
        width: 100%;

        * {
            font-family: 'Text-Font', sans-serif !important;
        }

        thead {
            background: $theme-color-5;

            * {
                color: $theme-color-6;
                text-align: center;
                font-size: 16px;
            }

            tr {
                th {
                    padding: 5px 0 5px;
                }
            }
        }

        tbody {
            box-shadow: none;

            td {
                background: $theme-color-5;
                font-size: 16px;
                text-align: center;
                width: 40px;
                height: 40px;
                padding: 5px 12px !important;
                font-family: 'Text-Font', sans-serif !important;

                @include respond-below(xs) {
                    width: 25px;
                    height: 25px;
                    padding: 3px 6px !important;
                }

                @include respond-below(xxs) {
                    width: 25px;
                    height: 25px;
                    padding: 1px 3px !important;
                }


                a {
                    width: 34px;
                    height: 34px;
                    padding: 0;
                    border: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &:hover {
                        background: $theme-color-4;
                        border-radius: 50%;
                    }
                }

                .ui-state-default {
                    color: $dark-color;
                    font-family: 'Text-Font', sans-serif;

                    &:hover {
                        color: $dark-color;
                    }
                }

                &.ui-state-disabled {
                    .ui-state-default {
                        color: $theme-color-4;
                    }
                }

                &.ui-datepicker-days-cell-over {
                    text-align: center;


                    .ui-state-default {
                        padding: 0;
                        color: $dark-color;
                    }
                }


                .ui-state-active {
                    background: $theme-color-6;
                    color: $theme-color-5 !important;
                    border-radius: 50%;

                    &:hover {
                        background: $theme-color-6;
                        color: $light-color;
                    }
                }


            }

            tr {
                line-height: 2.25;

                .ui-state-disabled {
                    opacity: 1;
                    background: $theme-color-5;
                    color: $theme-color-4;

                    .ui-state-default {
                        width: 34px;
                        height: 34px;
                        padding: 0;
                        border: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }
    }
}

// =======================
//         BookAWom
// =======================

.book-a-wom-section {
    //@include test-border(blue);

    .section-title {
        font-family: 'Headlines-Font', sans-serif !important;
    }

    .book-a-wom__simplex-container {
        width: 100%;
        z-index: 10;
        position: relative;
        display: flex;
        justify-content: center;

        * {
            // margin: 0 !important;
            font-family: 'Text-Font', sans-serif !important;
            text-align: center;
            //box-shadow: 0px 17px 27px 0 rgba(0, 0, 0, 0.06);
        }

        // Rooms Select middle row
        .engine-type-main .rooms-and-paxs-holder #extraRoomPaxPanel .panel-holder .table-structure-holder .panel-paxs .room-pax-row .pax-holder {
            width: 30% !important;
        }

        .engine-type-main div {
            font-family: 'Text-Font', sans-serif !important;
        }

        .engine-type-main .rooms-and-paxs-holder #extraRoomPaxPanel .panel-holder .table-structure-holder .panel-paxs .room-pax-row .pax-holder select {
            border: 1px solid $theme-color-6;
            outline-color: $theme-color-6;
        }

        .engine-type-main .datepickers .datepicker-holder .datepicker-custom-format .date-title {
            @include respond-below(md) {
                width: 100%;
            }
        }

        .engine-type-main {
            width: 100%;
            max-width: $main-max-width;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            box-shadow: none;
            cursor: default;

            @include respond-below(sm) {
                max-width: $section-center-max-width-sm;
            }

            .resort-holder {
                border: 1px solid $simplex-input-border-color;
                flex-basis: 44%;
                margin-bottom: 25px !important;
                height: $participate-form-input-height;
                order: 1;
                cursor: pointer;

                @include respond-below(sm) {
                    flex-basis: 100%;
                    height: $simplex-header-input-height-xs;
                    margin-bottom: 30px !important;
                }

                @include respond-below(xs) {
                    height: $simplex-header-input-height-xs;
                }

                .engine-header {

                }

                .select-hotel-holder {
                    height: 100%;

                    @include respond-below(sm) {
                        border: 1px solid $simplex-input-border-color;
                        height: $simplex-header-input-height-xs;
                    }

                    .hotel-header {

                    }

                    select {
                        border: none;
                        background: $theme-color-5;
                        height: 100%;
                        align-items: center;
                        font-size: 1rem;
                        text-align-last: center;
                        text-align: center;
                        outline: none !important;
                        white-space: pre-line;
                        color: $simplex-input-mobile-text-color;
                        cursor: pointer;

                        &:focus {
                            outline: none !important;
                        }

                        @include respond-below(sm) {
                            padding: $simplex-input-padding-xs;
                            text-align-last: left;
                            text-align: left;
                        }

                        @include respond-below(xs) {
                            font-size: 1.2rem;
                        }

                        option {
                        }

                        optgroup {
                            border-bottom: 1px solid $simplex-separator;
                            //font-size: 10rem !important;

                            .allSelect {
                                font-size: 1rem;
                                font-family: 'Text-Font', sans-serif;

                                @include respond-below(xs) {
                                    font-size: 1.2rem;
                                }
                            }

                            &:last-child {
                                border: none;
                            }
                        }

                        optgroup[label] {
                            font-size: 1rem;
                            background: inherit;

                            @include respond-below(xs) {
                                font-size: 1.2rem;
                            }
                        }

                    }
                }
            }

            #datePickersLoader {
                position: absolute !important;
            }

            .datepickers {
                flex-basis: 100%;
                order: 3;
                display: flex;
                justify-content: space-between;
                margin-bottom: 25px !important;
                cursor: default;

                @include respond-below(sm) {
                    flex-basis: 100%;
                    flex-wrap: wrap;
                    margin-bottom: 0 !important;
                }

                .datepicker-holder {
                    width: 44%;
                    height: $participate-form-input-height;
                    background: $theme-color-5;
                    border: 1px solid $simplex-input-border-color;

                    @include respond-below(sm) {
                        flex-basis: 100%;
                        height: $simplex-header-input-height-xs;
                        margin-bottom: 30px !important;
                    }

                    @include respond-below(xs) {
                        height: $simplex-header-input-height-xs;
                    }

                    .datepicker {
                        height: 100%;
                        cursor: pointer;

                        * {
                            font-size: 1rem;

                            @include respond-below(xs) {
                                font-size: 1.2rem;
                            }
                        }

                        .datepicker-input {
                            outline: none !important;
                        }

                        .datepicker-custom-format {
                            background: none;
                            height: 100%;
                            display: flex;
                            flex-flow: row wrap;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            padding-left: 0;
                            color: $simplex-input-mobile-text-color !important;

                            @include respond-below(sm) {
                                justify-content: flex-start;
                                padding: $simplex-input-padding-xs;
                            }

                            .date-title {
                                @include respond-below(sm) {
                                    width: unset !important;
                                    margin-right: 5px !important;
                                }
                            }

                            .date-elemnt-0 {
                                margin-right: 3px !important;
                            }

                            .date-elemnt-0, .date-elemnt-1 {
                                @include respond-below(md) {
                                    //color: $dark-color;
                                }
                            }
                        }
                    }


                }


            }

            .rooms-and-paxs-holder {
                flex-basis: 44%;
                margin-bottom: 25px !important;
                border: 1px solid $simplex-input-border-color;
                order: 2;
                height: $participate-form-input-height;
                cursor: pointer;

                @include respond-below(sm) {
                    flex-basis: 100%;
                    height: $simplex-header-input-height-xs;
                    margin-bottom: 30px !important;
                }

                @include respond-below(xs) {
                    height: $simplex-header-input-height-xs;
                }

                #extraRoomPaxPanel {
                    height: 100%;

                    @include respond-below(sm) {
                        height: $simplex-header-input-height-xs;
                    }

                    .panel-title {
                        height: 100%;
                        border: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: none;

                        span {
                            font-size: 1rem;
                            padding: 0 !important;
                            width: unset;
                            font-family: 'Text-Font', sans-serif !important;
                            color: $simplex-input-mobile-text-color;

                            @include respond-below(xs) {
                                font-size: 1.2rem;
                            }
                        }

                        @include respond-below(sm) {
                            justify-content: flex-start;
                            padding: $simplex-input-padding-xs;
                        }
                    }

                    .panel-holder {
                        background: $theme-color-5;
                        top: $simplex-header-height + 10px;
                        box-shadow: 0 17px 27px 0 rgba(0, 0, 0, 0.16);
                        right: 15px;

                        @include respond-below(sm) {
                            right: unset;
                        }

                        &::before {
                            border-color: transparent transparent $theme-color-5;
                        }

                        .close-panel {
                            width: 100%;
                            text-align: right !important;
                            padding: 10px !important;

                            &:hover {
                                font-weight: bold;
                            }
                        }

                        .table-structure-holder {
                            padding: 10px 30px 30px;

                            @include respond-below(lg) {
                                padding: 5px 10px 10px !important;
                            }

                            .paxs-header {
                                margin: 0 0 10px !important;
                                width: 100%;
                                display: flex;
                                justify-content: flex-start;

                                * {
                                    font-size: 1rem;

                                    @include respond-below(xs) {
                                        font-size: 1.2rem;
                                    }
                                }

                                .paxs-holder {
                                }
                            }

                            .panel-paxs {
                                .room {
                                }

                                .room-pax-row {
                                    margin: 0 0 20px !important;
                                    display: flex;
                                    justify-content: flex-start;

                                    .room-title {
                                        display: none;
                                    }

                                    .paxs-holder {
                                        width: 30% !important;

                                        select {
                                            outline-color: $theme-color-6;
                                            border: 1px solid $theme-color-6 !important;
                                        }
                                    }

                                    .remove-room-panel {
                                        position: relative;
                                        background-color: $theme-color-6;
                                    }
                                }
                            }

                            .bottom-panel-holder {
                                width: 100%;
                                display: flex;
                                justify-content: center;

                                #add-rooms-panel {
                                }

                                .close-panel-label {
                                    font-size: 1rem;
                                    background-color: $dark-btn-background;
                                    color: $text-light !important;
                                    border: none;
                                    transition: background-color 200ms;

                                    &:hover {
                                        background-color: $dark-btn-hover-background;
                                        color: $text-light;
                                    }

                                }
                            }
                        }
                    }
                }
            }

            .bottom-engine-holder {
                height: $participate-form-input-height;
                flex-basis: 100%;
                order: 5;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: flex-start;

                .promo-holder {
                    flex-basis: 44%;
                    justify-content: flex-start;
                    align-items: flex-start;
                    margin-bottom: 25px !important;

                    @include respond-below(sm) {
                        height: 35px !important;
                        flex-basis: 100%;
                        text-align: left !important;
                    }

                    .promo-input {
                        width: 100%;
                        height: 100%;
                        border: 1px solid $simplex-input-border-color;

                        input {
                            font-size: 1rem;
                            width: 100%;
                            height: 100%;

                            @include respond-below(sm) {
                                text-align: left !important;
                            }
                        }
                    }
                }

                .engine-button-row {
                    //width: 100%;
                    flex-grow: 1;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    cursor: default;

                    .search-button {
                        width: 207px !important;
                        height: 66px !important;
                        font-size: 18px;
                        line-height: 4;
                        cursor: pointer;

                        background-color: $dark-btn-background;
                        color: $text-light !important;
                        border: none;
                        transition: background-color 200ms;

                        &:hover {
                            //opacity: 0.7;
                            background-color: $dark-btn-hover-background !important;
                            color: $text-light !important;
                        }
                    }
                }
            }
        }
    }


    .ui-datepicker {

        width: 430px;

        @include respond-below(lg) {
            width: 50% !important;
        }

        @include respond-below(md) {
            width: 85% !important;
            left: 7% !important;
        }

        @include respond-below(xs) {
            width: 100% !important;
            left: 0 !important;
        }

        &::before {
            border-color: transparent transparent $theme-color-4 transparent !important;
        }

        * {
            font-family: 'Text-Font', sans-serif !important;
        }

        .ui-datepicker-header {
            background: $theme-color-4;
            color: $dark-color;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-family: 'Text-Font', sans-serif;
            width: 100%;
            height: 57px;
            padding: 0;

            &::after {
                content: none;
            }

            .ui-datepicker-prev, .ui-datepicker-next {
                position: relative;
                top: inherit;
                width: 0;
                height: 0;

                background: none;
                border: solid $theme-color-1;
                border-width: 0 1px 1px 0;
                display: inline-block;
                padding: 7px;
                cursor: pointer;
                transition: border-color 200ms;
                margin: 0 !important;

                &:hover {
                    border-color: $theme-color-6;
                }

                .ui-icon {
                    display: none;
                }

                .ui-icon-circle-triangle {

                }
            }

            .ui-datepicker-next {
                order: 3;
                //.ui-icon {
                transform: rotate(-45deg);
                //}
            }

            .ui-datepicker-prev {
                order: 1;
                //.ui-icon {
                transform: rotate(135deg);
                //}
            }

            //.arrow {
            //    border: solid $color;
            //    border-width: 0 1px 1px 0;
            //    //height: 26px;
            //    //width: 21px;
            //    display: inline-block;
            //    padding: 13px;
            //    cursor: pointer;
            //    transition: border-color 200ms;
            //
            //    @include respond-below(sm){
            //        padding: 9px;
            //    }
            //
            //
            //
            //    &.right {
            //    }
            //
            //    &.left {
            //
            //    }
            //}


            .ui-datepicker-title {
                order: 2;

                * {
                    color: $theme-color-6;
                    font-size: 1rem;
                }
            }
        }

        .ui-datepicker-calendar {
            box-shadow: 0 17px 27px 0 rgba(0, 0, 0, 0.16);
            width: 100%;

            * {
                font-family: 'Text-Font', sans-serif !important;
            }

            thead {
                background: $theme-color-5;

                * {
                    color: $theme-color-6;
                    text-align: center;
                    font-size: 16px;
                }

                tr {
                    th {
                        padding: 5px 0 5px;
                    }
                }
            }

            tbody {
                box-shadow: none;

                td {
                    background: $theme-color-5;
                    font-size: 16px;
                    text-align: center;
                    width: 40px;
                    height: 40px;
                    padding: 5px 12px !important;
                    font-family: 'Text-Font', sans-serif !important;

                    @include respond-below(xs) {
                        width: 25px;
                        height: 25px;
                        padding: 3px 6px !important;
                    }

                    @include respond-below(xxs) {
                        width: 25px;
                        height: 25px;
                        padding: 1px 3px !important;
                    }


                    a {
                        width: 34px;
                        height: 34px;
                        padding: 0;
                        border: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &:hover {
                            background: $theme-color-4;
                            border-radius: 50%;
                        }
                    }

                    .ui-state-default {
                        color: $dark-color;
                        font-family: 'Text-Font', sans-serif;

                        &:hover {
                            color: $dark-color;
                        }
                    }

                    &.ui-state-disabled {
                        .ui-state-default {
                            color: $theme-color-4;
                        }
                    }

                    &.ui-datepicker-days-cell-over {
                        text-align: center;


                        .ui-state-default {
                            padding: 0;
                            color: $dark-color;
                        }
                    }


                    .ui-state-active {
                        background: $theme-color-6;
                        color: $theme-color-5 !important;
                        border-radius: 50%;

                        &:hover {
                            background: $theme-color-6;
                            color: $light-color;
                        }
                    }


                }

                tr {
                    line-height: 2.25;

                    .ui-state-disabled {
                        opacity: 1;
                        background: $theme-color-5;
                        color: $theme-color-4;

                        .ui-state-default {
                            width: 34px;
                            height: 34px;
                            padding: 0;
                            border: none;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
            }
        }
    }
}


// =======================
//      ParticipateForm
// =======================

.participate-form-section {
    padding: $collection-item-content-padding;

    @include respond-below(sm) {
        padding: 0;
    }

    .missing-fields-message {
        color: red;
    }

    .participate-form {
        width: 100%;

        .participate-form-row {
            flex-basis: 100%;
            margin-bottom: 40px;

            @include respond-below(sm) {
                margin-bottom: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }

            .participate-form-col-50 {
                flex-basis: 44%;

                @include respond-below(sm) {
                    flex-basis: 100%;
                    margin-bottom: 30px;
                }
            }

            .participate-form-col-100 {
                flex-basis: 100%;
            }

            .participate-input {
                transition: 1s;
                outline: none;
                border: none;
                border-bottom: 1px solid $theme-color-6;
                min-height: $participate-form-input-height;

                &::placeholder {
                    color: $participate-form-input-color;
                }

                @include respond-below(sm) {
                    min-height: $participate-form-input-height-sm;
                }
            }

            .participate-select-container {
                padding: 0;
                border: 1px solid $theme-color-6;
                outline: none;
                background-color: $background-color;
                color: $participate-form-input-color;
                appearance: none;
                min-height: $participate-form-input-height;
                display: flex;
                position: relative;
                max-height: 66px;

                .arrow-down {
                    position: absolute;
                    right: 15px;
                    height: 11px;
                    width: 11px;
                    border-bottom: 2px solid #1738e8;
                    border-right: 2px solid #1738e8;
                    transform: rotate(45deg);
                    align-self: center;
                    margin-top: -2px;
                    z-index: 0;
                }

                .participate-select {
                    width: 100%;
                    padding-left: 21px;
                    margin: 0;
                    border: none;
                    appearance: none;
                    z-index: 10;
                    background: rgba(0, 0, 0, 0);

                    &.selected {
                        color: $theme-color-6;
                    }


                    @include respond-below(sm) {
                        min-height: $participate-form-input-height-sm;
                        padding-left: 12px;
                    }


                    .participate-select-option {
                        color: $theme-color-6;
                        min-height: $participate-form-input-height;

                        &:first-child {
                            color: $participate-form-input-color
                        }
                    }
                }
            }


            .participate-textarea {
                height: 177px;
                padding: 20px;
                border: 1px solid $theme-color-6;
                outline: none;

                &::placeholder {
                    color: $participate-form-input-color;
                }

                @include respond-below(sm) {
                    margin-bottom: 30px;
                    padding: 10px;
                }
            }
        }
    }
}

// =======================
//      TheWomLoader
// =======================

.the-wom-loader {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;

    .the-wom-spin-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .the-wom-spin {
        //display: inline-block;
        //width: 20px;
        //height: 20px;
        //border: 3px solid rgba($theme-color-1, 0.2);
        //border-radius: 50%;
        //border-top-color: $theme-color-1;
        //animation: the-wom-spin 1.5s ease-in-out infinite;
        //-webkit-animation: the-wom-spin 1.5s ease-in-out infinite;
        ////left: calc(50% - 15px);
        ////top: calc(50% - 15px);
        //position: absolute;
        ////z-index: 1;
        display: inline-block;
        width: 30px;
        height: 30px;
        border: 2px solid $theme-color-3;
        border-radius: 50%;
        border-top-color: $theme-color-6;
        animation: the-wom-spin 700ms linear infinite;
        -webkit-animation: the-wom-spin 700ms linear infinite;
        left: calc(50% - 15px);
        top: calc(50% - 15px);
        position: absolute;
        z-index: 1;
    }

    @keyframes the-wom-spin {
        0% {
            -webkit-transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @-webkit-keyframes the-wom-spin {
        0% {
            -webkit-transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(360deg);
        }
    }
}

// =======================
//    TheWomScrollbar
// =======================

.the-wom-scrollbar {
    margin-right: 2px;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background-color: rgba(0, 0, 0, 0);
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba($theme-color-6, 0.3);
        border-radius: 10px;
    }
}


// =======================
//    TermsAndConditions
// =======================

.terms-document {
    .terms-title {

    }

    .terms-document-container {
        width: 100%;
        max-width: $main-max-width;
        text-align: left;

        h2 {
            font-size: 2rem;
        }
    }
}


// =======================
//    Out source plugins
// =======================
.INDpositionRight #INDmenu-btn {
    right: -113px !important;
}

#INDmenu-btn {
    top: unset !important;
    bottom: 10% !important;
}

$size: 30%;
.background-container {
    position: fixed;
    top: (100% - $size) / 2;
    left: (100% - $size) / 8;
    right: (100% - $size) / 8;
    bottom: (100% - $size) / 2;
}

.background-container:before {
    content: '';
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 0 10px red;
    pointer-events: none;
}

.video-bg {
    background: white;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    /* overflow: hidden; */
}

.video-bg .video-fg,
.video-bg iframe,
.video-bg video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/*
  Full page video background
  Simulate object-fit: cover
  Based on http://fvsch.com/code/video-background/
*/

@media (min-aspect-ratio: #{"16/9"}) {
    .video-bg.cover .video-fg {
        height: 300%;
        top: -100%;
    }
}

@media (max-aspect-ratio: #{"16/9"}) {
    .video-bg.cover .video-fg {
        width: 300%;
        left: -100%;
    }
}

@supports (object-fit: cover) {
    .video-bg.cover .video-fg.supports-cover {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    .video-bg.cover video {
        object-fit: cover;
    }
}


/*
  Vertical centering for 16/9 youtube iframes and video elements
  Simulate object-fit: contain for entire element, not just contents of element
*/

.video-bg.contain {
    font-size: 0;
}

.video-bg.contain * {
    font-size: 16px;
}

.video-bg.contain:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.video-bg.contain .video-fg {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; /* 16:9 */
    pointer-events: none;
}

.video-bg.contain iframe,
.video-bg.contain video {
    pointer-events: auto;
}

@media (min-aspect-ratio: #{"16/9"}) {
    .video-bg.contain .video-fg {
        height: 100%;
        padding-bottom: 0;
        //max-width: calc(#{$size + vh} * 16 / 9);
        left: 50%;
        //margin-left: calc(#{$size + vh} * 16 / 9) / -2;
    }
}
